import {
  HIGH_SCHOOL_HEADING,
  MIDDLE_SCHOOL_HEADING,
} from "../modules/upcoming-events/UpcomingEvents.constants";

export const QUESTION_MINIMIZE_BREAKPOINT = 460;

export const GET_MORE_INFO = {
  HEADER: {
    DESKTOP: "Want to hear more about PacBay?",
    MOBILE: "Want to hear more?",
  },
  PARAGRAPH: {
    DESKTOP: "We'd love to answer any questions you have!",
    MOBILE: "Ask us a question you have!",
  },
  BUTTON_LABEL: {
    DESKTOP: "Submit An Inquiry",
  },
};

export const TOUR_VISIT = {
  HEADER: {
    DESKTOP: "Interested in visiting our campus?",
    MOBILE: "Interested in visiting?",
  },
  PARAGRAPH: {
    DESKTOP: "Schedule a tour with our admissions staff!",
    MOBILE: "Schedule a tour!",
  },
  BUTTON_LABEL: {
    DESKTOP: "Book A Visit",
  },
};

export const FINANCIAL_AID = {
  HEADER: {
    DESKTOP: "Want to apply for financial aid with FAST?",
    MOBILE: "Need financial aid?",
  },
  PARAGRAPH: {
    DESKTOP: "You can save your progress and finish at a later time",
    MOBILE: "You can save and finish later",
  },
  BUTTON_LABEL: {
    DESKTOP: "Apply To FAST",
  },
};

export const APPLY_TO_PACBAY = {
  HEADER: {
    DESKTOP: "Interested in applying to PacBay?",
    MOBILE: "Want to apply?",
  },
  PARAGRAPH: {
    DESKTOP: "Submit an inquiry to begin the process today!",
    MOBILE: "Begin the process today!",
  },
  BUTTON_LABEL: {
    DESKTOP: "Submit An Inquiry",
  },
};

export enum DEPTH {
  NEGATIVE = -1,
  ONE = 1,
  TEN = 10,
  TWENTY = 20,
  THIRTY = 30,
  FORTY = 50,
  FIFTY = 60,
  SIXTY = 70,
  OVER_EVERYTHING = 999999,
}

export const CAMPUS_TOUR = {
  HEADING: "Campus Tour",
  COPY: "Join us for a campus tour at PacBay. A faculty member will give you a tour of PacBay's campus, athletic sports fields, and more. This is a great opportunity to see the school and ask questions.",
  LINK_TEXT: "Sign up for a campus tour here.",
};

export const CAMPUS_VISIT = {
  HEADING: "Campus Visit Day",
  GET_COPY: (heading) => {
    const SHARED_TEMPLATAE =
      "During this event, your student will have the opportunity to experience part of the school day at PacBay. Your student will have lunch with PacBay students and then";

    let copy;
    switch (heading) {
      case MIDDLE_SCHOOL_HEADING:
        copy = `${SHARED_TEMPLATAE} spend the afternoon attending middle school classes.`;
        break;
      case HIGH_SCHOOL_HEADING:
        copy = `${SHARED_TEMPLATAE} attend a high school class.`;
        break;
      default:
        copy = `${SHARED_TEMPLATAE.split(". ")[0]}.`;
        break;
    }
    return copy;
  },
  GET_LINK_TEXT: (heading) => `Sign up for a ${heading} Campus Visit Day here!`,
};

export const OPEN_HOUSE = {
  HEADING: "Open House",
  COPY: "Open House is a wonderful event where students and parents are invited to learn more about our school. PacBay student ambassadors will take students and parents around campus and faculty members will share about their class offerings. Admissions representatives and several school administrators will also be present to share about the school's mission and answer any questions.",
  GET_LINK_TEXT: (heading) => `Sign up for a ${heading} Open House here!`,
};

export enum RELATED_PAGES_LABELS {
  ADMISSIONS = "Admissions",
  AFFORDING_PACBAY = "Affording PacBay",
  COLLEGE_COUNSELING = "College Counseling",
  HIGH_SCHOOL = "High School",
  HOME = "Home",
  HOW_TO_APPLY = "How To Apply",
  INQUIRY_FORM = "Inquiry Form",
  MIDDLE_SCHOOL = "Middle School",
  OUR_STORY = "Our Story",
}
