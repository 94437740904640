import React from "react";

export const Westmont = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 968 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="westmont"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          transform="translate(14.000000, 37.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <path
            d="M41.4369973,6.33070866 C31.5093834,6.33070866 28.0563003,10.2283465 31.077748,21.0551181 L49.6380697,88.1811024 L50.0697051,88.1811024 L81.5790885,3.2992126 L87.6219839,3.2992126 L118.268097,86.8818898 L118.699732,86.8818898 C125.605898,64.7952756 132.080429,42.7086614 138.986595,20.6220472 C142.008043,11.0944882 139.418231,6.33070866 126.469169,5.8976378 L126.469169,2 L161,2 L161,5.8976378 C154.525469,6.76377953 149.345845,11.5275591 148.050938,18.023622 L119.131367,111.566929 L111.793566,111.566929 L80.2841823,26.6850394 L49.2064343,112 L41.4369973,112 L17.6970509,27.984252 C13.3806971,12.8267717 8.20107239,6.33070866 0,6.33070866 L0,2 L41.8686327,2 L41.4369973,6.33070866 L41.4369973,6.33070866 Z"
            id="Path"
          ></path>
          <path
            d="M166,107.669291 C179.75,105.937008 178.916667,106.80315 178.916667,92.0787402 L178.916667,21.488189 C178.916667,6.76377953 179.333333,7.62992126 166,5.8976378 L166,2 L234.75,2 L234.75,27.1181102 L231,27.1181102 C229.333333,15.4251969 226.416667,6.76377953 215.583333,6.76377953 L195.166667,6.76377953 L195.166667,51.8031496 L207.666667,51.8031496 C217.25,51.8031496 221.416667,47.9055118 221.416667,38.3779528 L225.166667,38.3779528 L225.166667,71.7244094 L221,71.7244094 C220.583333,62.6299213 217.25,57 207.25,57 L194.75,57 L194.75,92.511811 C194.75,105.937008 200.583333,106.80315 214.333333,106.80315 C236.416667,106.80315 235.166667,100.307087 242.25,83.8503937 L246,83.8503937 L241.416667,112 L166,112 L166,107.669291 Z"
            id="Path"
          ></path>
          <path
            d="M317.568977,24.351145 C316.250012,13.8549618 310.094846,5.03816794 297.344858,5.03816794 C288.551763,4.19847328 280.637978,10.0763359 279.758669,18.0534351 C279.758669,18.8931298 279.758669,19.7328244 279.758669,20.9923664 C279.758669,31.4885496 290.750037,38.2061069 306.577608,49.5419847 C323.284488,61.7175573 329,69.2748092 329,81.870229 C329,99.9236641 309.655191,110 290.310382,110 C281.077633,110 271.844883,108.320611 263.051788,104.961832 C262.172479,104.541985 261.732824,103.70229 262.172479,102.862595 L262.172479,81.0305344 L266.129371,81.0305344 C266.129371,94.0458015 277.12074,104.541985 290.750037,104.541985 C291.189692,104.541985 291.189692,104.541985 291.629347,104.541985 C302.620715,104.961832 311.853465,96.5648855 312.29312,85.648855 C312.29312,85.648855 312.29312,85.2290076 312.29312,85.2290076 C312.29312,75.1526718 306.577608,67.1755725 282.836252,52.480916 C274.043157,47.0229008 263.491443,41.1450382 263.491443,24.351145 C263.491443,10.4961832 279.319014,0 297.784513,0 C305.258644,0 312.732774,0.839694656 320.206905,2.9389313 C321.086215,2.9389313 321.525869,3.77862595 321.086215,4.61832061 L321.086215,24.351145 L317.568977,24.351145 L317.568977,24.351145 Z"
            id="Path"
          ></path>
          <path
            d="M392.982063,90.3571429 C392.982063,105.198413 392.112108,104.325397 406.466368,106.071429 L406.466368,110 L362.96861,110 L362.96861,106.071429 C377.32287,104.325397 376.452915,105.198413 376.452915,90.3571429 L376.452915,4.8015873 L362.098655,4.8015873 C348.61435,4.8015873 345.134529,13.531746 339.914798,24.0079365 L336,24.0079365 L340.349776,0 L428.650224,0 L433,24.0079365 L429.085202,24.0079365 C423.865471,13.531746 420.820628,4.8015873 406.901345,4.8015873 L392.547085,4.8015873 L392.547085,90.3571429 L392.982063,90.3571429 Z"
            id="Path"
          ></path>
          <path
            d="M898.547085,90.3571429 C898.547085,105.198413 897.67713,104.325397 912.03139,106.071429 L912.03139,110 L868.533632,110 L868.533632,106.071429 C882.887892,104.325397 882.017937,105.198413 882.017937,90.3571429 L882.017937,4.8015873 L868.098655,4.8015873 C854.61435,4.8015873 851.134529,13.531746 845.914798,24.0079365 L842,24.0079365 L846.349776,0 L934.650224,0 L939,24.0079365 L934.650224,24.0079365 C929.430493,13.531746 926.38565,4.8015873 912.466368,4.8015873 L898.547085,4.8015873 L898.547085,90.3571429 Z"
            id="Path"
          ></path>
          <path
            d="M462.053254,27.3515625 L462.053254,27.3515625 L461.621302,90.515625 C461.621302,93.5234375 462.053254,96.1015625 462.485207,99.109375 C463.349112,104.695313 468.532544,106.84375 475.87574,107.703125 L475.87574,111.570312 L440.887574,111.570312 L440.887574,107.273437 C448.230769,106.414062 453.414201,104.265625 454.278107,98.6796875 C454.710059,96.1015625 455.142012,93.09375 455.142012,90.0859375 L455.142012,19.1875 C452.118343,11.453125 445.207101,6.296875 437,5.8671875 L437,2 L467.668639,2 L511.295858,88.796875 L556.218935,2 L583,2 L583,5.8671875 C571.769231,5.8671875 567.449704,11.8828125 567.449704,21.765625 L567.449704,91.8046875 C567.449704,106.414062 567.017751,105.554688 580.840237,107.273438 L580.840237,111.140625 L537.64497,111.140625 L537.64497,107.273438 C551.899408,105.554688 551.035503,106.414063 551.035503,91.8046875 L551.035503,26.4921875 L550.60355,26.4921875 L508.272189,112 L504.384615,112 L462.053254,27.3515625 Z"
            id="Path"
          ></path>
          <path
            d="M688.7532,57.0646388 C688.7532,20.4410646 668.814737,5.11026616 650.148941,5.11026616 C623.847139,5.11026616 613.241573,28.5323194 613.241573,49.3992395 C613.241573,86.8745247 632.331591,105.612167 652.270055,105.612167 C680.692971,106.038023 688.7532,77.9315589 688.7532,57.0646388 M595,57.9163498 C595,25.9771863 620.453358,0.425855513 651.845832,0 C652.270055,0 653.1185,0 653.542722,0 C682.814084,0 706.570551,23.4220532 706.994773,52.8060837 C707.418996,84.7452471 681.965638,111.148289 650.148941,112 C649.300496,112 648.876274,112 648.027828,112 C618.756467,112 595,88.5779468 595,59.1939163 C595,58.3422053 595,58.3422053 595,57.9163498"
            id="Shape"
          ></path>
          <path
            d="M815.636364,112 C790.692308,82.5511811 765.318182,51.8031496 739.944056,21.488189 L739.944056,90.7795276 C739.944056,93.8110236 740.374126,96.4094488 740.804196,99.4409449 C741.664336,105.070866 746.825175,107.23622 757.146853,108.102362 L757.146853,112 L718.870629,112 L718.870629,107.669291 C726.181818,106.80315 731.342657,104.637795 732.202797,99.007874 C732.632867,96.4094488 733.062937,93.3779528 733.062937,90.3464567 L733.062937,16.7244094 C729.192308,10.2283465 722.311189,6.33070866 715,5.8976378 L715,2 L745.965035,2 C769.188811,30.1496063 793.272727,59.1653543 816.496503,87.7480315 L816.926573,87.7480315 L816.926573,23.2204724 C816.926573,20.1889764 816.496503,17.5905512 816.066434,14.5590551 C815.206294,8.92913386 810.045455,6.76377953 799.723776,5.8976378 L799.723776,2 L838,2 L838,5.8976378 C830.688811,6.76377953 825.527972,8.92913386 824.667832,14.5590551 C824.237762,17.1574803 823.807692,20.1889764 823.807692,23.2204724 L823.807692,112 L815.636364,112 L815.636364,112 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};
