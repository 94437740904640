import React from "react";

export const Purdue = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 662 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="purdue"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="purdue-logo"
          transform="translate(14.000000, 38.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <path
            d="M12.1884058,11.8461538 L0.420289855,8.46153846 L0.420289855,0 L46.2318841,0 C76.0724638,0 87,9.73076923 87,32.1538462 C87,53.3076923 74.8115942,64.3076923 51.2753623,64.3076923 L32.7826087,64.3076923 L32.7826087,98.1538462 L44.5507246,101.538462 L44.5507246,110 L0,110 L0,101.961538 L12.1884058,98.5769231 L12.1884058,11.8461538 Z M32.7826087,11.8461538 L32.7826087,54.5769231 L54.6376812,52.8846154 C60.1014493,50.3461538 65.5652174,46.9615385 65.5652174,33.4230769 C65.5652174,22.8461538 65.1449275,11.8461538 44.9710145,11.8461538 L32.7826087,11.8461538 Z"
            id="Shape"
          ></path>
          <path
            d="M261.45,63.4615385 L246.575,63.4615385 L246.575,99 L258.475,102.384615 L258.475,110 L213,110 L213,101.961538 L225.325,98.5769231 L225.325,11.8461538 L213.425,8.46153846 L213.425,0 L261.875,0 C290.775,0 300.975,10.1538462 300.975,29.6153846 C300.975,44.8461538 295.025,52.4615385 281.425,57.9615385 L301.825,97.7307692 L315,101.961538 L315,110 L284.4,110 L261.45,63.4615385 Z M246.575,11.8461538 L246.575,53.7307692 L269.1,52.0384615 C276.325,48.6538462 279.725,42.7307692 279.725,31.3076923 C279.725,19.0384615 275.9,11.4230769 263.15,11.4230769 L246.575,11.8461538 Z"
            id="Shape"
          ></path>
          <path
            d="M322,102.384615 L333.949791,99 L333.949791,11.8461538 L322,8.46153846 L322,0 L375.774059,0 C406.075314,0 424,18.1923077 424,53.7307692 C424,93.9230769 405.648536,110 373.640167,110 L322,110 L322,102.384615 Z M355.288703,11.8461538 L355.288703,99 L371.079498,99 C382.175732,99 400.953975,97.3076923 400.953975,57.5384615 C400.953975,26.2307692 395.405858,11.8461538 371.079498,11.8461538 L355.288703,11.8461538 Z"
            id="Shape"
          ></path>
          <polygon
            id="Path"
            points="622.597087 78.2692308 614.572816 98.5769231 580.364078 98.5769231 580.364078 59.2307692 599.791262 59.2307692 602.325243 70.2307692 610.771845 70.2307692 610.771845 36.8076923 602.325243 36.8076923 599.791262 47.8076923 580.364078 47.8076923 580.364078 11.8461538 613.305825 11.8461538 619.218447 33 631.466019 33 630.621359 0 547.42233 0 547.42233 8.46153846 559.247573 11.8461538 559.247573 99 547 102.384615 547 110 632.31068 110 634 78.2692308"
          ></polygon>
          <path
            d="M192.360784,72.8977273 C192.360784,100.647727 179.058824,112 152.454902,112 C127.929412,112 110.054902,101.909091 110.054902,75.4204545 L110.054902,12.7727273 L98,9.40909091 L98,1 L142.062745,1 L142.062745,9.40909091 L130.423529,12.7727273 L130.423529,75.4204545 C130.423529,91.3977273 137.490196,98.125 154.94902,98.125 C167.003922,98.125 177.811765,90.9772727 177.811765,74.1590909 L177.811765,12.7727273 L165.756863,9.40909091 L165.756863,1 L204,1 L204,9.40909091 L192.360784,12.7727273 L192.360784,72.8977273 L192.360784,72.8977273 Z"
            id="Path"
          ></path>
          <path
            d="M525.992188,72.8977273 C525.992188,100.647727 512.742188,112 486.242188,112 C461.8125,112 443.59375,101.909091 443.59375,75.4204545 L443.59375,12.7727273 L432,9.40909091 L432,1 L475.890625,1 L475.890625,9.40909091 L464.296875,12.7727273 L464.296875,75.4204545 C464.296875,91.3977273 471.335937,98.125 488.726562,98.125 C500.734375,98.125 511.5,90.9772727 511.5,74.1590909 L511.5,12.7727273 L499.90625,9.40909091 L499.90625,1 L538,1 L538,9.40909091 L526.40625,12.7727273 L526.40625,72.8977273 L525.992188,72.8977273 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};
