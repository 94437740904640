import React from "react";

export const UCLA = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 433 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="ucla" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="ucla-logo"
          transform="translate(15.000000, 28.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <path
            d="M0.426220909,93.1487403 C0.0358149676,88.6378197 -0.086544541,84.1080256 0.0597769233,79.5828488 L6.65576867,6.24224806 L30.8716087,6.24224806 L24.24508,80.097626 C22.8098411,96.1162791 29.1615368,107.532219 45.2850722,107.532219 C61.4086076,107.532219 70.4780962,95.904312 71.9133352,81.7025194 L78.7841599,6 L103,6 L96.2818603,80.7335271 C93.5946044,109.470203 74.3562951,131 43.2696303,131 C19.0232532,131 3.57153179,115.465843 0.517831906,92.9973353"
            id="Path"
          ></path>
          <path
            d="M107.295409,68.2278396 C110.33148,33.1128697 134.255724,4.00639421 175.394495,4.00639421 C186.600296,3.83820223 197.602764,6.99147283 207,13.064426 L204.267536,42.0803212 C194.764631,29.7312045 183.470445,27.6780506 173.08708,27.6780506 C147.492997,27.6780506 133.435985,45.3714061 131.432178,67.7447446 C129.519453,89.5444078 140.965443,107.267957 165.618344,107.267957 C176.45712,107.267957 187.93347,104.248613 199.956314,92.171237 L197.284571,121.941968 C187.060588,127.755101 175.512292,130.87311 163.73598,131 C123.02226,131 104.38078,100.806561 107.295409,68.046679"
            id="Path"
          ></path>
          <path
            d="M227.014096,5 L251.281924,5 L242.415423,105.110616 L277.022388,105.110616 C279.476783,105.110616 287.760365,105.110616 290,104.867112 C290,106.084633 287.791045,128 287.791045,128 L216,128 L227.014096,5 Z"
            id="Path"
          ></path>
          <path
            d="M351.184624,51.2908876 C351.937725,49.643027 352.536562,47.9290491 352.97347,46.1708876 L353.337304,46.1708876 C353.480428,47.9220252 353.784913,49.6562367 354.246887,51.3514793 C355.550623,56.9259172 364.889009,87.9185799 364.889009,87.9185799 L334.569572,87.9185799 C334.569572,87.9185799 350.790471,52.1088757 351.123985,51.3211834 M357.187872,0 L355.520303,0 L290,128 L315.468327,128 L324.564158,108.580355 L370.831619,108.580355 L376.592312,128 L402,128 L357.187872,0 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
