import React from "react";

export const York = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 571 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="york" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="york-logo"
          transform="translate(13.000000, 30.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <path
            d="M37.7063197,121.802596 L41.8884758,121.802596 C50.5873606,121.802596 52.4944238,118.449432 52.4944238,109.429421 L52.4944238,81.8999459 L19.1040892,21.844781 C12.3457249,9.77339102 10.0371747,8.197404 1.57249071,8.197404 L0,8.197404 L0,3 C7.46096654,3 14.7881041,3.50297458 21.9144981,3.50297458 C29.0408922,3.50297458 36.669145,3.16765819 43.9628253,3 L43.9628253,8.197404 L40.8513011,8.197404 C37.204461,8.197404 34.4275093,9.43807463 34.4275093,13.7971877 C35.0333748,17.430157 36.3280853,20.9131597 38.2416357,24.0578691 L63.9702602,70.566252 L87.9591078,24.5943753 C90.1911104,20.7573372 91.7738498,16.5764131 92.6431227,12.2212006 C92.6431227,9.27041644 90.5687732,8.197404 85.9516729,8.197404 L82.605948,8.197404 L82.605948,3 C89.732342,3 94.4163569,3.50297458 99.802974,3.50297458 C105.189591,3.50297458 111.446097,3.16765819 117,3 L117,8.197404 L112.650558,8.197404 C105.557621,8.197404 103.98513,10.4775554 98.598513,20.5705787 L67.8178439,78.2449973 L67.8178439,109.429421 C67.8178439,119.690103 71.6319703,121.802596 78.4237918,121.802596 L81.7695167,121.802596 L81.7695167,127 C74.4758364,127 66.9814126,126.497025 59.6877323,126.497025 C52.394052,126.497025 44.063197,126.832342 37.605948,127 L37.7063197,121.802596 Z"
            id="Path"
          ></path>
          <path
            d="M192.662586,7.33133051 C168.34447,7.33133051 145.397803,22.2545875 145.397803,62.4839493 C145.397803,90.5944244 163.828727,121.475885 193.699535,121.475885 C225.844939,121.475885 240.529469,97.8724334 240.529469,65.9560269 C240.529469,38.045864 222.968243,7.33133051 192.562236,7.33133051 M193.264685,0.0533214992 C216.849673,-0.906237035 239.055883,11.144281 251.071088,31.4227949 C263.086293,51.7013089 262.967502,76.9285587 250.761856,97.0934607 C238.556209,117.258363 216.237496,129.100029 192.662586,127.91926 C155.131739,127.91926 128.003827,99.7753991 128.003827,65.4552465 C127.814982,48.0988657 134.621314,31.3946023 146.893412,19.0959777 C159.165509,6.79735315 175.874098,-0.0641344048 193.264685,0.0533214992"
            id="Shape"
          ></path>
          <path
            d="M316.077501,62.2159183 C319.950096,63.2222717 323.954754,63.6382626 327.95374,63.4495832 C343.665365,63.4495832 356.618204,56.3476741 356.618204,38.3094918 C356.618204,30.7074482 351.369781,9.56843237 327.079003,9.56843237 C323.401451,9.59615582 319.728422,9.82992496 316.077501,10.2686206 L316.077501,62.2159183 Z M286,121.131756 L289.162511,121.131756 C297.203364,121.131756 300.702313,118.164291 300.702313,106.927938 L300.702313,22.3718742 C300.702313,11.1355203 297.337939,8.16805593 289.162511,8.16805593 L286,8.16805593 L286,3 C292.12316,3 300.500451,3.50013444 308.373085,3.50013444 C320.081105,3.50013444 328.559327,3 336.162812,3 C367.11505,3 372.69991,27.0731379 372.69991,35.0419468 C372.69991,57.7147083 356.618204,64.6499059 347.870832,67.4173165 L371.421448,105.860984 C381.043557,121.465179 383.835987,122.532132 398,123.36569 L398,127 C394.635626,127 391.540403,127 388.209673,127 C372.834485,127 365.668369,125.099489 356.416341,110.028771 L335.960949,76.9532132 C331.923701,70.5514923 329.66957,70.284754 316.212076,69.8513041 L316.212076,102.760151 C316.212076,119.197903 319.172725,121.131756 326.305197,121.131756 L331.351757,121.131756 L331.351757,126.299812 C323.680985,126.299812 316.346651,125.799677 308.642235,125.799677 C300.937819,125.799677 292.39231,126.1331 286.26915,126.299812 L286,121.131756 Z"
            id="Shape"
          ></path>
          <path
            d="M424,121.642182 L427.155245,121.642182 C435.177622,121.642182 438.668531,118.661896 438.668531,107.376992 L438.668531,22.4555766 C438.668531,11.1706724 435.311888,8.19038617 427.155245,8.19038617 L424,8.19038617 L424,3 C430.109091,3 438.500699,3.50229544 446.321678,3.50229544 C454.142657,3.50229544 461.325874,3.16743181 469.012587,3 L469.012587,8.19038617 L463.944056,8.19038617 C456.962238,8.19038617 453.874126,10.1325952 453.874126,26.6413719 L453.874126,67.5282204 L492.206993,25.1679719 C493.952448,23.2592493 500.565035,16.2940859 500.565035,12.8115042 C500.565035,9.3289225 498.114685,8.29084526 494.825175,8.29084526 L491.602797,8.29084526 L491.602797,3 C498.047552,3 505.398601,3.50229544 512.179021,3.50229544 C518.959441,3.50229544 525.974825,3.16743181 532.923077,3 L532.923077,8.19038617 L529.801399,8.19038617 C521.241958,8.19038617 518.288112,9.76424521 507.983217,20.5468539 L474.416783,55.8749662 L519.395804,108.415069 C529.163636,119.900891 534.232168,121.809614 541.381818,121.809614 L544,121.809614 L544,127 C538.394406,127 532.654545,126.497705 527.216783,126.497705 C521.779021,126.497705 515.871329,126.832568 510.433566,127 C509.560839,121.977046 509.023776,119.365109 499.088112,108.046719 L463.071329,66.9924386 L454.008392,76.5695382 L454.008392,103.191196 C454.008392,119.699973 456.962238,121.642182 464.078322,121.642182 L469.146853,121.642182 L469.146853,126.832568 C461.46014,126.832568 454.142657,126.330273 446.455944,126.330273 C438.769231,126.330273 430.243357,126.665136 424.134266,126.832568 L424,121.642182 Z"
            id="Path"
          ></path>
        </g>
      </g>
    </svg>
  );
};
