import React from "react";

export const OpenDoor = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill={color}
      fillOpacity={opacity}
    >
      <path d="M22 25.5q.65 0 1.075-.45.425-.45.425-1.05 0-.65-.425-1.075Q22.65 22.5 22 22.5q-.6 0-1.05.425-.45.425-.45 1.075 0 .6.45 1.05.45.45 1.05.45ZM13 42v-3l14-2.45v-23.3q0-.7-.375-1.225-.375-.525-1.025-.625L13 9.45V6.5l13.3 2.3q1.6.25 2.65 1.525Q30 11.6 30 13.2V39Zm-7 0v-3h4V9.5q0-1.3.875-2.15Q11.75 6.5 13 6.5h22q1.3 0 2.15.85.85.85.85 2.15V39h4v3Zm7-3h22V9.5l-22-.05Z" />
    </svg>
  );
};
