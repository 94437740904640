import {
  Application,
  Apply,
  Book,
  Brush,
  Calendar,
  CreditCard,
  Document,
  Donate,
  Envelope,
  LargeSchool,
  Map,
  Movie,
  OpenDoor,
  Payments,
  Phone,
  Register,
  SmallSchool,
  Tour,
  Venmo,
  Visit,
} from "../../components/misc";
import {
  Baylor,
  Berkeley,
  Boston,
  CalPoly,
  Clark,
  Emory,
  Gonzaga,
  HawaiiPacific,
  Illinois,
  LMU,
  NWU,
  NYU,
  Purdue,
  SantaClara,
  SeattlePacific,
  SMoC,
  UCI,
  UCLA,
  UCSD,
  UNC,
  UoSF,
  USC,
  Westmont,
  York,
} from "../../components/universities";
import { SVGIcons } from "../../SvgWrapper.enum";

export const getIcon = (iconType) => {
  let Icon = null;
  switch (iconType) {
    //* universities
    case SVGIcons.Baylor:
      Icon = Baylor;
      break;
    case SVGIcons.Berkeley:
      Icon = Berkeley;
      break;
    case SVGIcons.Boston:
      Icon = Boston;
      break;
    case SVGIcons.CalPoly:
      Icon = CalPoly;
      break;
    case SVGIcons.Clark:
      Icon = Clark;
      break;
    case SVGIcons.Emory:
      Icon = Emory;
      break;
    case SVGIcons.Gonzaga:
      Icon = Gonzaga;
      break;
    case SVGIcons.HawaiiPacific:
      Icon = HawaiiPacific;
      break;
    case SVGIcons.Illinois:
      Icon = Illinois;
      break;
    case SVGIcons.LMU:
      Icon = LMU;
      break;
    case SVGIcons.NWU:
      Icon = NWU;
      break;
    case SVGIcons.NYU:
      Icon = NYU;
      break;
    case SVGIcons.Purdue:
      Icon = Purdue;
      break;
    case SVGIcons.SantaClara:
      Icon = SantaClara;
      break;
    case SVGIcons.SeattlePacific:
      Icon = SeattlePacific;
      break;
    case SVGIcons.SMoC:
      Icon = SMoC;
      break;
    case SVGIcons.UCI:
      Icon = UCI;
      break;
    case SVGIcons.UCLA:
      Icon = UCLA;
      break;
    case SVGIcons.UCSD:
      Icon = UCSD;
      break;
    case SVGIcons.UNC:
      Icon = UNC;
      break;
    case SVGIcons.UoSF:
      Icon = UoSF;
      break;
    case SVGIcons.USC:
      Icon = USC;
      break;
    case SVGIcons.Westmont:
      Icon = Westmont;
      break;
    case SVGIcons.York:
      Icon = York;
      break;

    //* misc
    case SVGIcons.Application:
      Icon = Application;
      break;
    case SVGIcons.Apply:
      Icon = Apply;
      break;
    case SVGIcons.Book:
      Icon = Book;
      break;
    case SVGIcons.Brush:
      Icon = Brush;
      break;
    case SVGIcons.Calendar:
      Icon = Calendar;
      break;
    case SVGIcons.CreditCard:
      Icon = CreditCard;
      break;
    case SVGIcons.Document:
      Icon = Document;
      break;
    case SVGIcons.Donate:
      Icon = Donate;
      break;
    case SVGIcons.Envelope:
      Icon = Envelope;
      break;
    case SVGIcons.LargeSchool:
      Icon = LargeSchool;
      break;
    case SVGIcons.Map:
      Icon = Map;
      break;
    case SVGIcons.Movie:
      Icon = Movie;
      break;
    case SVGIcons.OpenDoor:
      Icon = OpenDoor;
      break;
    case SVGIcons.Payments:
      Icon = Payments;
      break;
    case SVGIcons.Phone:
      Icon = Phone;
      break;
    case SVGIcons.Register:
      Icon = Register;
      break;
    case SVGIcons.SmallSchool:
      Icon = SmallSchool;
      break;
    case SVGIcons.Tour:
      Icon = Tour;
      break;
    case SVGIcons.Visit:
      Icon = Visit;
      break;
    case SVGIcons.Venmo:
      Icon = Venmo;
      break;
    default:
      throw "Improper type choice";
  }
  return Icon;
};
