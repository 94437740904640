import React from "react";
import { SvgWrapper } from "components/svg-wrapper/SvgWrapper.component";
import { motion } from "framer-motion";
import classNames from "classnames";
import { ButtonVariant } from "./types";

export const Button = ({
  children,
  className,
  style,
  labelStyle,
  onClick,
  variant = ButtonVariant.SECONDARY,
  disabled,
  tabIndex,
  hoverAnimation,
  hoverTrigger,
  icon,
  fullWidth,
}) => {
  let hoverAnimationProps;
  if (hoverAnimation) {
    const translateYValue = "translateY(-0.25rem)";
    hoverAnimationProps = {
      whileHover: {
        transform: translateYValue,
      },
      whileFocus: {
        transform: translateYValue,
      },
      animate: {
        transform: hoverTrigger ? translateYValue : "translateY(0rem)",
      },
    };
  }

  return (
    <motion.button
      tabIndex={tabIndex}
      className={classNames([className, variant], {})}
      style={style}
      onClick={onClick}
      disabled={disabled}
      fullWidth={fullWidth}
      {...hoverAnimationProps}
    >
      {icon && <SvgWrapper iconType={icon} width="2.25rem" height="2.25rem" />}
      <p
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          ...labelStyle,
        }}
      >
        {children}
      </p>
    </motion.button>
  );
};
