import { createContext } from "react";

const store = {
  isMenuOpen: false,
  toggleIsMenuOpen: () => {},
  isSplashPageLoading: false,
  toggleIsSplashPageLoading: () => {},
  relatedPages: [],
  setRelatedPages: () => {},
  isScrollYPassedTriggerPoint: () => {},
};

export const Context = createContext(store);
