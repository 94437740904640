import React from "react";

export const Clark = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 566 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="clark"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="clark-logo"
          transform="translate(16.000000, 34.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <g id="Group">
            <path
              d="M96.6891354,31.584445 C91.4257108,11.291537 80.1951675,5.82072988 62.9949088,5.82072988 C31.4065714,5.82072988 17.3716381,29.8217744 17.3716381,57.5257328 C17.3716381,91.5857507 34.7432762,114.176659 63.1714815,114.176659 C82.8255815,114.176659 91.9502356,104.645181 100.550365,86.1175549 L104.936119,86.6450504 C102.653657,94.2336735 98.7950245,108.353318 95.9880379,115.056688 C91.0725654,116.291863 75.279695,120 62.9975055,120 C18.599857,120 0,89.6481187 0,61.5890149 C0,25.2336083 27.9036805,0 66.3316136,0 C81.2494104,0 93.0044785,3.52795247 97.919951,4.58816617 C99.3247426,14.2946054 100.028437,22.0581899 101.077486,31.2371336 L96.6891354,31.584445 Z"
              id="Path"
            ></path>
            <path
              d="M151.878492,95.0223054 C151.878492,104.329206 152.649699,108.669293 155.578729,110.217831 C158.507758,111.923052 164.215211,112.236415 170.075867,112.236415 C177.785343,112.236415 185.03521,111.458229 188.426445,107.737036 C190.89327,104.942876 193.513297,99.6731443 195.826919,92.5415098 L199.680358,93.0063326 C198.911748,97.3490305 195.517916,113.166061 194.284504,117.352077 L124.738229,117.352077 L124.738229,113.784954 C137.690356,112.855309 138.926365,111.771593 138.926365,94.8630122 L138.926365,39.0398886 C138.926365,22.1365308 137.690356,21.0528148 124.738229,20.120558 L124.738229,16.5560464 L166.37563,16.5560464 L166.37563,20.120558 C153.114501,21.0528148 151.878492,22.1365308 151.878492,39.0398886 L151.878492,95.0223054 Z"
              id="Path"
            ></path>
            <path
              d="M275.557284,113.784954 L280.490934,113.16345 C286.198387,112.544556 286.813794,110.836724 285.27138,106.031815 C284.193767,102.310622 279.722323,90.2147847 275.404082,79.0485931 L244.09878,79.0485931 C242.553769,83.0805388 238.547126,95.1789872 236.386707,101.691729 C233.457678,110.682654 234.844293,112.701238 241.782562,113.320131 L246.409805,113.784954 L246.409805,117.352077 L213.411483,117.352077 L213.411483,113.784954 C223.43458,112.701238 224.667993,111.771593 230.066443,97.9679237 L261.831353,15.6237895 L265.378387,14.2267099 L276.325895,43.6881161 C283.573166,63.3856331 290.355635,83.0779274 296.374687,98.8975693 C301.308336,111.923052 303.312956,113.009379 312.873848,113.784954 L312.873848,117.352077 L275.557284,117.352077 L275.557284,113.784954 Z M246.103399,73.1547451 L273.399462,73.1547451 L259.829331,35.0079429 L259.520328,35.0079429 L246.103399,73.1547451 Z"
              id="Shape"
            ></path>
            <path
              d="M357.590885,94.8656236 C357.590885,111.771593 358.826894,112.85792 371.470019,113.787566 L371.470019,117.354688 L330.453219,117.354688 L330.453219,113.784954 C343.405346,112.855309 344.638758,111.771593 344.638758,94.8630122 L344.638758,39.0398886 C344.638758,22.1365308 343.402749,21.0528148 331.068627,20.120558 L331.068627,16.5560464 L370.236607,16.5560464 C380.103905,16.5560464 388.896187,17.9505147 394.601044,21.8283899 C400.768105,25.7062651 404.779941,32.6864405 404.779941,41.677366 C404.779941,54.5487781 396.914665,63.7016082 384.424743,68.8172698 C387.197973,73.4681087 393.520834,84.1720889 398.145481,90.9929711 C403.697134,99.2109329 406.935166,103.396949 411.100205,108.050399 C414.33564,111.925663 417.111467,114.406459 422.66312,115.803538 L422.356715,118.749157 L420.198892,118.749157 C402.463722,118.284334 397.067868,112.85792 391.209809,104.331817 C386.426766,97.3516419 379.950702,85.8773094 375.632461,78.5863817 C373.165637,74.5570474 370.698812,73.0058973 365.918366,73.0058973 L357.590885,73.0058973 L357.590885,94.8656236 L357.590885,94.8656236 Z M357.590885,68.5065175 L366.53637,68.5065175 C372.859231,68.5065175 377.79288,67.7283311 381.955322,64.31789 C388.584588,59.0455465 390.900807,51.4490893 390.900807,43.8474093 C390.900807,27.5655561 379.182092,21.0528148 367.61658,21.0528148 C362.373928,21.0528148 360.213509,21.3609558 358.982694,21.8257785 C358.058284,22.1365308 357.593482,22.9121058 357.593482,26.014406 L357.593482,68.5065175 L357.590885,68.5065175 Z"
              id="Shape"
            ></path>
            <path
              d="M465.845533,65.2475355 C473.401806,59.0455465 494.834616,36.2483407 502.697295,26.4792288 C505.319919,23.2228581 504.857714,21.3609558 500.692675,20.896133 L494.372411,20.120558 L494.372411,16.5560464 L531.535772,16.5560464 L531.535772,20.120558 C521.972283,21.0528148 518.118843,21.8257785 507.942543,31.2867495 C502.390889,36.2483407 490.516375,47.5686027 478.335455,60.2859443 C492.061386,76.5677975 508.86955,95.1763759 515.654615,102.310622 C524.135298,111.150088 526.911125,113.011991 534,113.784954 L534,117.352077 L511.180575,117.352077 C503.777504,109.134115 476.484038,76.721868 465.845533,65.5582878 L465.845533,94.8656236 C465.845533,111.771593 467.078945,112.85792 480.340075,113.787566 L480.340075,117.354688 L439.476477,117.354688 L439.476477,113.784954 C451.6548,112.855309 452.893406,111.771593 452.893406,94.8630122 L452.893406,39.0398886 C452.893406,22.1365308 451.6548,21.0528148 438.70527,20.120558 L438.70527,16.5560464 L479.568867,16.5560464 L479.568867,20.120558 C467.076349,21.0528148 465.845533,22.1365308 465.845533,39.0398886 L465.845533,65.2475355 L465.845533,65.2475355 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
