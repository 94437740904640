import React from "react";

export const UCI = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 838 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="uci" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="uci-wordmark"
          transform="translate(14.000000, 31.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <g id="Group" transform="translate(346.000000, 0.000000)">
            <path
              d="M37.1462404,26.8853316 C37.1462404,39.493519 29.9382652,46.0462898 19.1582412,46.0462898 C8.50086269,46.0462898 1.23156472,39.493519 1.23156472,27.2570655 L1.23156472,2.09965544 L6.09905598,2.09965544 L6.09905598,26.94686 C6.09905598,36.2812256 11.02787,41.5342098 19.2808867,41.5342098 C27.2272897,41.5342098 32.2787491,36.7119242 32.2787491,27.2570655 L32.2787491,2.09965544 L37.1462404,2.09965544 L37.1462404,26.8853316 Z"
              id="Path"
            ></path>
            <path
              d="M51.3603369,45.3643505 L46.6180462,45.3643505 L46.6180462,13.4106198 L51.3603369,13.4106198 L51.3603369,18.9738094 C53.45553,15.5128389 56.7822878,12.7312441 62.2655615,12.7312441 C69.9641185,12.7312441 74.4611183,17.9226999 74.4611183,25.5265803 L74.4611183,45.3669141 L69.7188276,45.3669141 L69.7188276,26.7007465 C69.7188276,20.7683867 66.5147153,17.0587391 60.9087962,17.0587391 C55.4255226,17.0587391 51.3603369,21.0760285 51.3603369,27.0724804 L51.3603369,45.3643505 Z"
              id="Path"
            ></path>
            <path
              d="M83.4423424,6.42715042 L83.4423424,1.17416629 L88.8642933,1.17416629 L88.8642933,6.42715042 L83.4423424,6.42715042 Z M83.7515111,45.3643505 L83.7515111,13.4106198 L88.4938018,13.4106198 L88.4938018,45.3643505 L83.7515111,45.3643505 L83.7515111,45.3643505 Z"
              id="Shape"
            ></path>
            <polygon
              id="Path"
              points="121.87636 13.4106198 126.989142 13.4106198 113.130206 45.6130276 108.93982 45.6130276 95.1422072 13.4106198 100.377635 13.4106198 111.096336 39.9267812"
            ></polygon>
            <path
              d="M145.39618,41.9649083 C149.767979,41.9649083 152.849446,40.1728947 155.437776,37.4528283 L158.394042,40.1113663 C155.18993,43.6953936 151.311268,46.1078182 145.273535,46.1078182 C136.527381,46.1078182 129.380728,39.3704623 129.380728,29.4182493 C129.380728,20.1479757 135.850276,12.7312441 144.657752,12.7312441 C154.083566,12.7312441 159.505517,20.2710324 159.505517,29.6669265 C159.505517,30.097625 159.505517,30.5924156 159.444194,31.1512982 L134.189452,31.1512982 C134.861447,38.0732393 139.790261,41.9649083 145.39618,41.9649083 Z M154.696793,27.6262357 C154.203656,21.8169326 150.876898,16.7485336 144.532552,16.7485336 C138.99051,16.7485336 134.800124,21.3862341 134.184342,27.6262357 L154.696793,27.6262357 Z"
              id="Shape"
            ></path>
            <path
              d="M171.317807,45.3643505 L166.575516,45.3643505 L166.575516,13.4106198 L171.317807,13.4106198 L171.317807,21.7554042 C173.658291,16.4408918 178.277936,12.6081874 184.315669,12.8543008 L184.315669,17.9842282 L183.945178,17.9842282 C176.985048,17.9842282 171.317807,22.9910989 171.317807,32.6331064 L171.317807,45.3643505 L171.317807,45.3643505 Z"
              id="Path"
            ></path>
            <path
              d="M200.816592,45.9847615 C196.25827,45.9847615 191.20681,44.1927479 187.634762,41.2855326 L190.036568,37.8860905 C193.485972,40.4805365 197.305866,41.9649083 201.061883,41.9649083 C204.881778,41.9649083 207.654076,39.9883096 207.654076,36.8965093 L207.654076,36.7734526 C207.654076,33.5585955 203.895504,32.3229009 199.707673,31.1487346 C194.717536,29.7258911 189.17294,27.9979696 189.17294,22.1245744 L189.17294,22.0015177 C189.17294,16.4998564 193.731263,12.8543008 200.014287,12.8543008 C203.895504,12.8543008 208.208536,14.2130522 211.471416,16.3767997 L209.3149,19.9608269 C206.358634,18.0431929 202.970553,16.8690266 199.891641,16.8690266 C196.133069,16.8690266 193.731263,18.8456253 193.731263,21.5041634 L193.731263,21.6272201 C193.731263,24.6549284 197.673803,25.8290947 201.925512,27.1288814 C206.854326,28.6132532 212.151076,30.5283236 212.151076,36.2760983 L212.151076,36.399155 C212.151076,42.4596989 207.160939,45.9847615 200.816592,45.9847615 Z"
              id="Path"
            ></path>
            <path
              d="M219.841968,6.42715042 L219.841968,1.17416629 L225.263919,1.17416629 L225.263919,6.42715042 L219.841968,6.42715042 Z M220.148581,45.3643505 L220.148581,13.4106198 L224.890872,13.4106198 L224.890872,45.3643505 L220.148581,45.3643505 L220.148581,45.3643505 Z"
              id="Shape"
            ></path>
            <path
              d="M241.087737,36.2812256 C241.087737,40.1754584 243.244252,41.5957381 246.448365,41.5957381 C248.050421,41.5957381 249.404631,41.2855326 251.06801,40.4831002 L251.06801,44.561918 C249.404631,45.4258788 247.618607,45.9206694 245.339445,45.9206694 C240.226663,45.9206694 236.345446,43.3877518 236.345446,36.8965093 L236.345446,17.6124944 L231.909769,17.6124944 L231.909769,13.4080561 L236.345446,13.4080561 L236.345446,3.76604865 L241.087737,3.76604865 L241.087737,13.4080561 L251.190655,13.4080561 L251.190655,17.6124944 L241.087737,17.6124944 L241.087737,36.2812256 Z"
              id="Path"
            ></path>
            <path
              d="M273.851957,46.41546 C271.140982,53.0297592 268.059515,55.4396202 263.255901,55.4396202 C260.606249,55.4396202 258.636256,54.8833012 256.47974,53.8321916 L258.081797,50.0635793 C259.62253,50.8660117 261.038063,51.2377456 263.071933,51.2377456 C265.905554,51.2377456 267.691579,49.7533738 269.602803,45.2412937 L255.12553,13.4106198 L260.360958,13.4106198 L271.940732,40.049838 L282.166297,13.4106198 L287.217756,13.4106198 L273.851957,46.41546 Z"
              id="Path"
            ></path>
            <path
              d="M325.271062,46.1078182 C315.722603,46.1078182 308.762473,38.5680299 308.762473,29.4823414 C308.762473,20.3966529 315.78648,12.7312441 325.393707,12.7312441 C334.942167,12.7312441 341.963618,20.2710324 341.963618,29.3567209 C341.963618,38.4424095 334.880844,46.1078182 325.271062,46.1078182 Z M325.271062,16.9331187 C318.372255,16.9331187 313.627409,22.4347799 313.627409,29.3567209 C313.627409,36.278662 318.678869,41.8418516 325.391152,41.8418516 C332.289959,41.8418516 337.096128,36.3401903 337.096128,29.4797777 C337.098682,22.4963083 331.9859,16.9331187 325.271062,16.9331187 Z"
              id="Shape"
            ></path>
            <path
              d="M365.85393,13.5336765 L365.85393,17.6124944 L355.812334,17.6124944 L355.812334,45.3643505 L351.070043,45.3643505 L351.070043,17.615058 L346.695689,17.615058 L346.695689,13.4747118 L351.070043,13.4747118 L351.070043,10.693117 C351.070043,3.70708397 354.580769,0 360.679825,0 C362.897664,0 364.374519,0.246113458 365.915253,0.802432421 L365.915253,4.94277862 C364.190551,4.44798802 362.895108,4.1403462 361.293052,4.1403462 C357.598358,4.1403462 355.751011,6.36562205 355.751011,10.9392305 L355.751011,13.5362402 L365.85393,13.5362402 L365.85393,13.5336765 Z"
              id="Path"
            ></path>
            <path
              d="M21.6213706,118.201116 C9.17796778,118.201116 0,108.374523 0,95.8893925 C0,83.5273186 9.11664505,73.4546126 21.8053388,73.4546126 C29.6290964,73.4546126 34.3100644,76.2362074 38.623096,80.3150252 L35.2963382,83.8990525 C31.6629668,80.438082 27.5977812,77.9666926 21.7440161,77.9666926 C12.1955568,77.9666926 5.04890432,85.6936298 5.04890432,95.7663358 C5.04890432,105.841605 12.2568796,113.691599 21.7440161,113.691599 C27.6565488,113.691599 31.5377662,111.404795 35.6668297,107.449034 L38.8683869,110.599799 C34.3739422,115.173407 29.4451283,118.201116 21.6213706,118.201116 Z"
              id="Path"
            ></path>
            <path
              d="M47.1725059,91.6234259 L45.7544179,87.7291932 C49.2651439,86.1217646 52.7145471,85.0706551 57.3341924,85.0706551 C66.0190232,85.0706551 70.8865145,89.5186431 70.8865145,97.9275196 L70.8865145,117.457648 L66.328192,117.457648 L66.328192,112.637926 C64.1103534,115.542578 60.4156593,118.139587 54.8097402,118.139587 C48.8972075,118.139587 42.920797,114.801674 42.920797,108.125846 C42.920797,101.326962 48.4628382,97.7429345 56.5344418,97.7429345 C60.5996274,97.7429345 63.4971262,98.2992535 66.328192,99.1042496 L66.328192,97.9916116 C66.328192,92.2438369 62.817466,89.277657 56.8410555,89.277657 C53.0875937,89.2750933 50.1287722,90.2646745 47.1725059,91.6234259 Z M47.7269655,107.941261 C47.7269655,112.020079 51.4216597,114.368411 55.7346913,114.368411 C61.5859013,114.368411 66.3895147,110.784384 66.3895147,105.715985 L66.3895147,102.624185 C64.0490307,101.944809 60.9062411,101.265433 57.0275787,101.265433 C51.0537233,101.267997 47.7269655,103.862443 47.7269655,107.941261 Z"
              id="Shape"
            ></path>
            <polygon
              id="Path"
              points="80.1794624 117.460212 80.1794624 72.339411 84.9217531 72.339411 84.9217531 117.460212"
            ></polygon>
            <path
              d="M94.8355936,78.5230116 L94.8355936,73.2674638 L100.257544,73.2674638 L100.257544,78.5204479 L94.8355936,78.5204479 L94.8355936,78.5230116 Z M95.1422072,117.460212 L95.1422072,85.506481 L99.8844979,85.506481 L99.8844979,117.460212 L95.1422072,117.460212 Z"
              id="Shape"
            ></path>
            <path
              d="M126.245604,85.6295377 L126.245604,89.7083556 L116.204008,89.7083556 L116.204008,117.460212 L111.461717,117.460212 L111.461717,89.7083556 L107.089918,89.7083556 L107.089918,85.5654457 L111.461717,85.5654457 L111.461717,82.7864145 C111.461717,75.8003815 114.974998,72.0932975 121.071499,72.0932975 C123.289338,72.0932975 124.766193,72.339411 126.306927,72.8957299 L126.306927,77.0360761 C124.582225,76.5412855 123.289338,76.2336437 121.687282,76.2336437 C117.992587,76.2336437 116.14524,78.4589196 116.14524,83.032528 L116.14524,85.6295377 L126.245604,85.6295377 Z"
              id="Path"
            ></path>
            <path
              d="M144.964366,118.201116 C135.415907,118.201116 128.455777,110.661327 128.455777,101.575639 C128.455777,92.4899504 135.479784,84.8245416 145.089566,84.8245416 C154.638026,84.8245416 161.659478,92.36433 161.659478,101.450018 C161.659478,110.535707 154.574148,118.201116 144.964366,118.201116 Z M144.964366,89.0289799 C138.065559,89.0289799 133.320713,94.5306411 133.320713,101.452582 C133.320713,108.374523 138.372173,113.937713 145.087011,113.937713 C151.985818,113.937713 156.791986,108.436052 156.791986,101.575639 C156.791986,94.5921695 151.679204,89.0289799 144.964366,89.0289799 Z"
              id="Shape"
            ></path>
            <path
              d="M173.719613,117.460212 L168.977323,117.460212 L168.977323,85.506481 L173.719613,85.506481 L173.719613,93.8512654 C176.060097,88.5341893 180.679743,84.7040486 186.717476,84.950162 L186.717476,90.0800894 L186.346984,90.0800894 C179.386855,90.0800894 173.719613,95.0869601 173.719613,104.728968 L173.719613,117.460212 L173.719613,117.460212 Z"
              id="Path"
            ></path>
            <path
              d="M197.428512,117.460212 L192.686221,117.460212 L192.686221,85.506481 L197.428512,85.506481 L197.428512,91.0696706 C199.523705,87.6087001 202.850463,84.8271053 208.333736,84.8271053 C216.032293,84.8271053 220.529293,90.0185611 220.529293,97.6224414 L220.529293,117.462775 L215.787002,117.462775 L215.787002,98.7966077 C215.787002,92.8642479 212.58289,89.1546003 206.976971,89.1546003 C201.493697,89.1546003 197.428512,93.1718897 197.428512,99.1683416 L197.428512,117.460212 Z"
              id="Path"
            ></path>
            <path
              d="M229.510517,78.5230116 L229.510517,73.2674638 L234.932468,73.2674638 L234.932468,78.5204479 L229.510517,78.5204479 L229.510517,78.5230116 Z M229.819686,117.460212 L229.819686,85.506481 L234.561977,85.506481 L234.561977,117.460212 L229.819686,117.460212 Z"
              id="Shape"
            ></path>
            <path
              d="M246.200519,91.6234259 L244.782431,87.7291932 C248.293157,86.1217646 251.74256,85.0706551 256.362205,85.0706551 C265.047036,85.0706551 269.914527,89.5186431 269.914527,97.9275196 L269.914527,117.457648 L265.356205,117.457648 L265.356205,112.637926 C263.138366,115.542578 259.443672,118.139587 253.837753,118.139587 C247.92522,118.139587 241.94881,114.801674 241.94881,108.125846 C241.94881,101.326962 247.490851,97.7429345 255.562455,97.7429345 C259.62764,97.7429345 262.525139,98.2992535 265.356205,99.1042496 L265.356205,97.9916116 C265.356205,92.2438369 261.845479,89.277657 255.869068,89.277657 C252.113051,89.2750933 249.156785,90.2646745 246.200519,91.6234259 Z M246.754978,107.941261 C246.754978,112.020079 250.449673,114.368411 254.762704,114.368411 C260.613914,114.368411 265.417528,110.784384 265.417528,105.715985 L265.417528,102.624185 C263.077044,101.944809 259.934254,101.265433 256.055592,101.265433 C250.079181,101.267997 246.754978,103.862443 246.754978,107.941261 Z"
              id="Shape"
            ></path>
            <path
              d="M280.436485,117.460212 L278.218646,117.460212 L278.218646,110.971533 L283.885888,110.971533 L283.885888,116.532159 C283.885888,121.539029 281.729372,123.94889 277.048404,125 L276.307421,122.836253 C279.391443,121.726178 280.684331,120.11875 280.436485,117.460212 Z"
              id="Path"
            ></path>
            <polygon
              id="Path"
              points="315.970449 74.1955166 315.970449 117.460212 311.105512 117.460212 311.105512 74.1955166"
            ></polygon>
            <path
              d="M331.303685,117.460212 L326.561394,117.460212 L326.561394,85.506481 L331.303685,85.506481 L331.303685,93.8512654 C333.644169,88.5341893 338.263814,84.7040486 344.301547,84.950162 L344.301547,90.0800894 L343.931056,90.0800894 C336.970927,90.0800894 331.303685,95.0869601 331.303685,104.728968 L331.303685,117.460212 Z"
              id="Path"
            ></path>
            <polygon
              id="Path"
              points="373.861656 85.506481 378.974438 85.506481 365.115502 117.708889 360.925116 117.708889 347.127503 85.506481 352.362931 85.506481 363.081632 112.022642"
            ></polygon>
            <path
              d="M385.308564,78.5230116 L385.308564,73.2674638 L390.730515,73.2674638 L390.730515,78.5204479 L385.308564,78.5204479 L385.308564,78.5230116 Z M385.617733,117.460212 L385.617733,85.506481 L390.360024,85.506481 L390.360024,117.460212 L385.617733,117.460212 Z"
              id="Shape"
            ></path>
            <path
              d="M404.890954,117.460212 L400.148664,117.460212 L400.148664,85.506481 L404.890954,85.506481 L404.890954,91.0696706 C406.986147,87.6087001 410.312905,84.8271053 415.796179,84.8271053 C423.494736,84.8271053 427.991736,90.0185611 427.991736,97.6224414 L427.991736,117.462775 L423.249445,117.462775 L423.249445,98.7966077 C423.249445,92.8642479 420.045333,89.1546003 414.439414,89.1546003 C408.95614,89.1546003 404.890954,93.1718897 404.890954,99.1683416 L404.890954,117.460212 Z"
              id="Path"
            ></path>
            <path
              d="M450.895773,114.06077 C455.270128,114.06077 458.349039,112.268756 460.937369,109.548689 L463.893636,112.207228 C460.689523,115.791255 456.810861,118.201116 450.770573,118.201116 C442.024419,118.201116 434.877767,111.466323 434.877767,101.514111 C434.877767,92.2438369 441.344759,84.8271053 450.15479,84.8271053 C459.578049,84.8271053 465,92.3668936 465,101.762788 C465,102.193486 465,102.688277 464.941232,103.247159 L439.683935,103.247159 C440.36104,110.166537 445.289854,114.06077 450.895773,114.06077 Z M460.198942,99.7220969 C459.705805,93.9127938 456.379047,88.8443948 450.032145,88.8443948 C444.487549,88.8443948 440.299718,93.4795316 439.683935,99.7220969 L460.198942,99.7220969 Z"
              id="Shape"
            ></path>
          </g>
          <g id="Group">
            <path
              d="M0,67.083182 L0,2.3025149 L32.4004237,2.3025149 L32.4004237,66.4242217 C32.4004237,83.0725839 40.8296768,90.9852354 53.7255598,90.9852354 C66.7860163,90.9852354 75.2178409,83.4033461 75.2178409,67.247281 L75.2178409,2.3025149 L107.620836,2.3025149 L107.620836,66.2575586 C107.620836,103.34651 86.2931286,119.830773 53.3964126,119.830773 C20.6616988,119.830773 0,103.182411 0,67.083182 Z"
              id="Path"
            ></path>
            <path
              d="M123.155039,60.3269161 L123.155039,59.998718 C123.155039,26.3712314 148.944234,0 183.993263,0 C207.630144,0 222.837772,9.88953227 233.08762,23.8994893 L208.954447,42.5272964 C202.338075,34.45311 194.734261,29.0096365 183.661545,29.0096365 C167.458762,29.0096365 156.056898,42.8580586 156.056898,59.6705199 L156.056898,60.001282 C156.056898,77.3086045 167.461333,90.8211363 183.661545,90.8211363 C195.726846,90.8211363 202.836939,85.2161279 209.782458,76.8163073 L233.915631,93.9569667 C223.002345,108.95921 208.29101,120 182.836106,120 C149.769673,119.994872 123.155039,94.774898 123.155039,60.3269161 Z"
              id="Path"
            ></path>
            <polygon
              id="Path"
              points="251.928723 2.3025149 284 2.3025149 284 117.684665 251.928723 117.684665"
            ></polygon>
          </g>
        </g>
      </g>
    </svg>
  );
};
