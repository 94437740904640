import React from "react";

export const LMU = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 400 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="lmu" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="loyola-marymount-university"
          transform="translate(16.000000, 26.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <path
            d="M0,0 L27.9785408,0 L27.9785408,108.362214 L89.8179031,108.362214 L89.8179031,132.811627 L0,132.811627 L0,0 Z M258.744942,79.3851314 L258.744942,0 L286.421827,0 L286.421827,76.5930688 C286.421827,99.2314142 296.376456,110.09782 313.872471,110.09782 C331.368486,110.09782 341.323115,99.3068753 341.323115,76.5930688 L341.323115,0 L369,0 L369,79.3851314 C369,115.908329 347.129982,135 313.872471,135 C280.61496,135 258.744942,115.908329 258.744942,79.3851314 M170.586143,104.287311 L132.275904,47.1632197 L132.275904,132.811627 L105.277744,132.811627 L105.277744,0 L130.993869,0 L171.264868,62.7082169 L211.158798,0 L236.497854,0 L236.497854,132.811627 L208.971796,132.811627 L208.971796,47.1632197 L171.264868,104.287311 L170.586143,104.287311 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  );
};
