import { Link } from "components/link/Link.component";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React from "react";
import "./RelatedPages.styles.scss";

export const RelatedPages = ({ relatedPages }) => {
  const windowWidth = useWindowWidth();
  const relatedText = (() => {
    let returnedText;
    if (windowWidth <= 400) {
      returnedText = "";
    } else if (windowWidth <= 500) {
      returnedText = "Related";
    } else {
      returnedText = "Related Pages";
    }
    return returnedText;
  })();
  return (
    <section className="related-pages">
      {relatedText && <p>{relatedText}:&nbsp;</p>}
      <p className="page-links">
        {relatedPages
          .map((page) => (
            <Link
              key={page.label}
              to={page.link}
              newTab={page.newTab}
              className="animated-link"
            >
              <span>{page.label}</span>
            </Link>
          ))
          .reduce((prev, curr) => [prev, ",\u00A0", curr])}
      </p>
    </section>
  );
};
