import linksService from "services/links.service";

export const footerCategories = [
  {
    columnName: "Contact Us",
    isContactInfo: true,
    columnItems: [
      {
        label: "650-355-1935",
        link: linksService.phone(),
      },
      {
        label: "Admissions Email",
        link: linksService.email(),
      },
      {
        label: "Inquiry Form",
        link: linksService.inquiryForm(),
        newTab: true,
      },
    ],
  },
  {
    columnName: "Links",
    columnItems: [
      {
        label: "School Calendar",
        link: linksService.schoolCalendar(),
        newTab: true,
      },
      {
        label: "myPacBay",
        link: linksService.myPacbay(),
        newTab: true,
      },
      {
        label: "Covid-19",
        link: linksService.covid19(),
        newTab: true,
      },
      {
        label: "Donations",
        link: linksService.donations(),
      },
      {
        label: "Careers",
        link: linksService.careers(),
        newTab: true,
      },
    ],
  },
  {
    columnName: "Follow",
    columnItems: [
      {
        label: "Facebook",
        link: linksService.facebook(),
        newTab: true,
      },
      {
        label: "Instagram",
        link: linksService.instagram(),
        newTab: true,
      },
      {
        label: "Twitter",
        link: linksService.twitter(),
        newTab: true,
      },
      {
        label: "LinkedIn",
        link: linksService.linkedin(),
        newTab: true,
      },
    ],
  },
];
