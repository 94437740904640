import { Button } from "components/button/Button.component";
import { Link } from "components/link/Link.component";
import { SvgWrapper } from "components/svg-wrapper/SvgWrapper.component";
import { Context } from "contexts/Context.store";
import { AnimatePresence, motion } from "framer-motion";
import { useWindowWidth } from "hooks/windowWidth.hook";
import React, { useContext } from "react";
import { menuCallsToAction, menuPages } from "./Menu.config";
import "./Menu.styles.scss";
import { menuItemVariants, menuVariants } from "./Menu.variants";

const MotionLink = motion(Link);

export const MenuComponent = ({ location }) => {
  const { isMenuOpen, toggleIsMenuOpen } = useContext(Context);
  const windowWidth = useWindowWidth();

  const handleSamePageClick = (link) => {
    if (location.pathname === link) {
      toggleIsMenuOpen(false);
    }
  };

  return (
    <AnimatePresence>
      {isMenuOpen && (
        <div className="centered menu-grid-wrapper">
          <motion.section
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            {menuCallsToAction.map((action) => {
              return (
                <motion.div key={action.label} variants={menuItemVariants}>
                  <Link
                    to={action.link}
                    newTab={action.newTab}
                    className="no-underline"
                    onClick={() => {
                      handleSamePageClick(action.link);
                    }}
                  >
                    <Button
                      tabIndex={-1}
                      icon={action.icon}
                      className="call-to-action lg"
                    >
                      {action.label}
                    </Button>
                  </Link>
                </motion.div>
              );
            })}
          </motion.section>
          <motion.section
            className="menu-pages-wrapper"
            variants={menuVariants}
            initial="closed"
            animate="open"
            exit="closed"
          >
            {menuPages.map((page) => {
              return (
                <MotionLink
                  key={page.label}
                  to={page.link}
                  variants={menuItemVariants}
                  className="call-to-action menu-pages invert-color"
                  onClick={() => {
                    handleSamePageClick(page.link);
                  }}
                >
                  {windowWidth > 600 && (
                    <SvgWrapper
                      iconType={page.icon}
                      width="3rem"
                      color="var(--white)"
                    />
                  )}
                  <p style={windowWidth <= 600 ? { margin: 0 } : {}}>
                    {page.label}
                  </p>
                </MotionLink>
              );
            })}
          </motion.section>
        </div>
      )}
    </AnimatePresence>
  );
};
