import { useLocation } from "@reach/router";
import React from "react";
import { Header } from "./components/header/Header.component";

export const MarketingLayout = ({ children }) => {
  const location = useLocation();
  return (
    <>
      <Header location={location} hideMenu />
      <main>{children}</main>;
    </>
  );
};
