import React from "react";

export const Movie = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 29 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="MovieButton"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g id="Movie-Button" stroke={color} fillOpacity={opacity}>
          <rect
            id="Rectangle"
            stroke-width="2"
            fill="var(--white)"
            x="1"
            y="1"
            width="27"
            height="27"
            rx="8"
          ></rect>
          <g
            id="Lines"
            transform="translate(6.000000, 2.000000)"
            stroke-linecap="square"
            stroke-width="2"
          >
            <line x1="14.5" y1="4.5" x2="17" y2="0" id="Line-3"></line>
            <line x1="7.5" y1="4.5" x2="10" y2="0" id="Line-2"></line>
            <line x1="0.5" y1="4.5" x2="3" y2="0" id="Line-1"></line>
          </g>
          <line
            x1="1"
            y1="7.5"
            x2="28"
            y2="7.5"
            id="Line"
            stroke-width="2"
            stroke-linecap="square"
          ></line>
          <path
            d="M15,13.118034 L19.190983,21.5 L10.809017,21.5 L15,13.118034 Z"
            id="Play-Icon"
            fill={color}
            fillOpacity={opacity}
            transform="translate(15.000000, 17.000000) rotate(90.000000) translate(-15.000000, -17.000000) "
          ></path>
        </g>
      </g>
    </svg>
  );
};
