import { Context } from "contexts/Context.store";
import FocusTrap from "focus-trap-react";
import React, { useContext, useEffect } from "react";
import { Footer } from "./components/footer/Footer.component";
import { Header } from "./components/header/Header.component";
import { MenuComponent } from "./components/menu-side-panel/components/menu/Menu.component";
import { MenuSidePanel } from "./components/menu-side-panel/MenuSidePanel.component";
import { StaticImage } from "gatsby-plugin-image";
import { AnimatePresence, motion } from "framer-motion";
import { DEPTH } from "../components/constants";
import { useWindowWidth } from "hooks/windowWidth.hook";
import { useDevice } from "hooks/device.hook";

export const DefaultLayout = ({ children, location }) => {
  const { isSplashPageLoading, isMenuOpen, toggleIsMenuOpen } =
    useContext(Context);
  const { isMobile, isTablet, isDesktop } = useDevice();

  useEffect(() => {
    toggleIsMenuOpen(false);
  }, [location.pathname]);

  const BADGE_ASPECT_RATIO = 0.85;
  const BADGE_WINDOW_OFFSET = 15;

  const BADGE_DESKTOP_HEIGHT = 180;
  const BADGE_TABLET_HEIGHT = 120;
  const BADGE_MOBILE_HEIGHT = 80;

  let HEIGHT = BADGE_DESKTOP_HEIGHT;
  if (isTablet) HEIGHT = BADGE_TABLET_HEIGHT;
  if (isMobile) HEIGHT = BADGE_MOBILE_HEIGHT;

  return (
    <>
      <FocusTrap active={isMenuOpen}>
        <div>
          <Header location={location} />
          <MenuSidePanel>
            <MenuComponent location={location} />
          </MenuSidePanel>
        </div>
      </FocusTrap>
      <AnimatePresence>
        {!isSplashPageLoading && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 1,
            }}
            transition={{
              type: "tween",
              duration: 0.5,
            }}
          >
            <StaticImage
              alt="2024 Niche Best Schools Badge"
              layout="constrained"
              placeholder="blurred"
              src="../images/png/2024_Niche_Best_Schools.png"
              style={{
                height: HEIGHT,
                width: HEIGHT * BADGE_ASPECT_RATIO,
                position: "fixed",
                bottom: BADGE_WINDOW_OFFSET,
                right: BADGE_WINDOW_OFFSET,
                zIndex: DEPTH.OVER_EVERYTHING,
              }}
            />
          </motion.div>
        )}
      </AnimatePresence>
      <>
        {/* current route's page */}
        {children}
      </>
      <Footer />
    </>
  );
};
