export enum SVGIcons {
  //* universities
  Baylor = "baylor",
  Berkeley = "berkeley",
  Boston = "boston",
  CalPoly = "cal-poly",
  Clark = "clark",
  Emory = "emory",
  Gonzaga = "gonzaga",
  HawaiiPacific = "hawaii-pacific",
  Illinois = "illinois",
  LMU = "lmu",
  NWU = "nwu",
  NYU = "nyu",
  Purdue = "purdue",
  SantaClara = "santa-clara",
  SeattlePacific = "seattle-pacific",
  SMoC = "smoc",
  UCI = "uci",
  UCLA = "ucla",
  UCSD = "ucsd",
  UoSF = "uosf",
  UNC = "unc",
  USC = "usc",
  Westmont = "westmont",
  York = "york",

  //* misc
  Application = "application",
  Apply = "apply",
  Book = "book",
  Brush = "brush",
  Calendar = "calendar",
  CreditCard = "credit-card",
  Document = "document",
  Donate = "donate",
  Envelope = "envelope",
  LargeSchool = "large-school",
  Map = "map",
  Movie = "movie",
  OpenDoor = "open-door",
  Payments = "payments",
  Phone = "phone",
  Register = "register",
  SmallSchool = "small-school",
  Tour = "tour",
  Visit = "visit",
  Venmo = "venmo",
}
