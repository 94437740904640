import React from "react";

export const NWU = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={width}
      height={height}
      style={{ maxWidth, maxHeight, enableBackground: "new 0 0 144 36" }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      fill={color}
      fillOpacity={opacity}
      version="1.1"
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 144 36"
      xmlSpace="preserve"
    >
      <g>
        <path
          class="st0"
          d="M56.2,5.4h-5.9v0.8c1.8,0.1,2.3,0.5,2.4,1.5c0,0.7,0.2,1.9,0.2,4.1v4.9h-0.1L43.1,5.4h-4.2v0.8   C40,6.3,40.5,6.5,41,7.1s0.5,0.9,0.5,2.6v5.5c0,2.2-0.1,3.3-0.2,4.1c-0.1,1.1-0.7,1.5-2.1,1.6v0.8h5.9v-0.8   c-1.8-0.1-2.2-0.5-2.4-1.6c-0.1-0.7-0.2-1.8-0.2-4v-6l0,0L53,21.6h1v-9.8c0-2.2,0.1-3.4,0.2-4.1c0.1-1.1,0.7-1.4,2-1.5V5.4z"
        />
        <path
          class="st0"
          d="M62.2,21.8c2.7,0,5.5-1.9,5.5-5.8c0-2.9-2.2-5.5-5.5-5.5c-2.5,0-5.6,1.9-5.6,5.9C56.7,19,58.6,21.8,62.2,21.8z    M62,11.3c1.8,0,2.7,2,2.7,5.2c0,3.5-1.1,4.3-2.2,4.3c-1.7,0-2.8-2.3-2.8-5.4C59.7,12.7,60.8,11.3,62,11.3z"
        />
        <path
          class="st0"
          d="M76.3,10.6c-1,0-2,1.1-2.8,2.6l0,0v-2.6c-1.4,0.5-2.7,0.8-4.1,0.9v0.6c1.2,0.2,1.4,0.3,1.4,1.7V19   c0,1.5-0.2,1.7-1.6,1.8v0.7h6.3v-0.7c-1.9-0.1-2-0.3-2-1.8v-4.5c0.5-1.1,1-1.6,1.6-1.6c0.4,0,0.7,0.2,1.1,0.5   c0.1,0.1,0.4,0.2,0.7,0.1c0.6-0.2,1-0.9,1-1.6C77.9,11.3,77.3,10.6,76.3,10.6z"
        />
        <path
          class="st0"
          d="M84.4,20.1c-0.5,0-1.4-0.3-1.4-2.2v-6.1h2.4c0.4-0.2,0.4-0.9,0.1-1.1H83V7.3l-0.3-0.1L80.3,9v1.8h-1l-0.7,0.8   l0.1,0.3h1.6v6.9c0,2.1,1,3,2.8,3c0.2,0,0.6-0.1,1-0.3l1.9-0.9l-0.2-0.8C85.3,20,84.9,20.1,84.4,20.1z"
        />
        <path
          class="st0"
          d="M98.2,19.1v-4.4c0-2.8-1.3-4.1-3.4-4.1c-1.2,0-2.5,0.8-3.3,1.6V4.5c-1.3,0.3-2.9,0.7-4.1,0.9v0.5   c1.4,0.1,1.5,0.2,1.5,1.7V19c0,1.6-0.2,1.7-1.6,1.8v0.7h5.6v-0.7c-1.2-0.1-1.3-0.3-1.3-1.8v-6c0.6-0.6,1.3-0.9,2-0.9   c1.2,0,2,0.8,2,2.7V19c0,1.4-0.1,1.6-1.4,1.8v0.7h5.5v-0.7C98.3,20.7,98.2,20.6,98.2,19.1z"
        />
        <path
          class="st0"
          d="M101.2,13c0.9,2.8,1.9,5.7,2.9,8.7h0.9c0.9-2.5,1.8-4.7,2.8-7.1c0.9,2.3,1.9,4.6,2.9,7.1h0.8   c1.2-3.4,2.2-6.4,3.1-8.5c0.5-1.3,0.7-1.5,1.9-1.7v-0.7H112v0.7c1.5,0.2,1.7,0.5,1.4,1.5c-0.3,1.3-1,3.7-1.5,5l0,0   c-1-2.4-1.9-4.7-2.8-7.1h-0.9c-0.9,2.5-1.8,4.8-2.6,7l0,0c-0.5-1.7-1-3.4-1.6-5.1c-0.3-0.9-0.2-1.1,1.3-1.4v-0.7h-5.7v0.7   C100.6,11.6,100.8,11.8,101.2,13z"
        />
        <path
          class="st0"
          d="M121.8,21.8c0.9,0,2.8-0.5,4.2-2.6l-0.4-0.6c-0.9,0.8-1.8,1.1-2.7,1.1c-1.7,0-3.3-1.3-3.4-4.2   c2.6-0.1,5.6-0.3,6-0.4c0.5-0.1,0.7-0.4,0.7-0.9c0-1.9-1.7-3.8-3.8-3.8c-1.3,0-2.6,0.7-3.7,1.8c-1,1-1.7,2.6-1.7,4.3   C116.9,19.3,118.6,21.8,121.8,21.8z M119.6,14.4c0.2-1.9,1.1-3,2.1-3c1.1,0,1.7,1.2,1.7,2.5c0,0.4-0.2,0.5-0.6,0.5"
        />
        <path
          class="st0"
          d="M132.4,14.7c-1.2-0.5-1.9-1.1-1.9-1.9c0-0.9,0.6-1.5,1.4-1.5c1.2,0,2,1,2.6,2.5l0.7-0.2   c-0.1-0.5-0.3-2.2-0.6-2.7c-0.5-0.2-1.2-0.4-2.3-0.4c-2.5,0-4,1.5-4,3.3c0,2,1.9,2.9,3.1,3.5c1.4,0.7,1.9,1.3,1.9,2.1   c0,0.9-0.7,1.5-1.5,1.5c-1.5,0-2.6-1.6-3.1-3.1l-0.7,0.1c0,1.1,0.2,2.8,0.4,3.1c0.5,0.4,1.7,0.8,3,0.8c2,0,4.2-1.2,4.2-3.5   C135.6,16.4,134.4,15.6,132.4,14.7z"
        />
        <path
          class="st0"
          d="M138.4,18.7c0,2.1,1,3,2.8,3c0.2,0,0.6-0.1,1-0.3l1.9-0.9l-0.2-0.8c-0.4,0.2-0.8,0.3-1.3,0.3s-1.4-0.3-1.4-2.2   v-6.1h2.4c0.4-0.2,0.4-0.9,0.1-1.1H141V7.3l-0.3-0.1L138.4,9v1.8h-1l-0.7,0.8l0.1,0.3h1.6L138.4,18.7L138.4,18.7z"
        />
      </g>
      <g>
        <path
          class="st0"
          d="M56.4,30.9V27H55v3.9c0,0.9-0.5,1.5-1.5,1.5S52,31.8,52,30.9V27h-1.4v3.9c0,1.6,1,2.7,2.9,2.7   S56.4,32.5,56.4,30.9z"
        />
        <polygon
          class="st0"
          points="65,31.1 62,27 60.6,27 60.6,33.5 62,33.5 62,29.3 65.1,33.5 66.4,33.5 66.4,27 65,27  "
        />
        <rect x="70.6" y="27" class="st0" width="1.4" height="6.5" />
        <polygon
          class="st0"
          points="79.8,33.5 82.3,27 80.7,27 78.9,32 77.1,27 75.5,27 78.1,33.5  "
        />
        <polygon
          class="st0"
          points="90.5,28.2 90.5,27 85.8,27 85.8,33.5 90.5,33.5 90.5,32.3 87.2,32.3 87.2,30.8 90.4,30.8 90.4,29.6    87.2,29.6 87.2,28.2  "
        />
        <path
          class="st0"
          d="M98.2,33.5h1.6L98.3,31c0.7-0.2,1.4-0.8,1.4-1.9c0-1.2-0.8-2.1-2.2-2.1h-3v6.5h1.4v-2.3h1L98.2,33.5z    M95.9,28.2h1.5c0.6,0,1,0.3,1,0.9c0,0.5-0.4,0.9-1,0.9h-1.5V28.2z"
        />
        <path
          class="st0"
          d="M108.6,31.6c0-1.5-1.4-1.8-2.4-2.1c-0.7-0.2-1.3-0.3-1.3-0.7c0-0.4,0.3-0.7,0.9-0.7c0.7,0,1.4,0.2,1.9,0.7   l0.8-1c-0.6-0.6-1.5-0.9-2.5-0.9c-1.5,0-2.5,0.9-2.5,2c0,1.5,1.4,1.8,2.4,2c0.7,0.2,1.3,0.3,1.3,0.8c0,0.4-0.4,0.7-1.1,0.7   c-0.9,0-1.6-0.4-2.1-0.9l-0.8,1.1c0.6,0.6,1.5,1,2.8,1C107.7,33.6,108.6,32.7,108.6,31.6z"
        />
        <rect x="112.5" y="27" class="st0" width="1.4" height="6.5" />
        <polygon
          class="st0"
          points="117.7,28.2 119.6,28.2 119.6,33.5 121,33.5 121,28.2 122.9,28.2 122.9,27 117.7,27  "
        />
        <polygon
          class="st0"
          points="130.8,27 129.2,29.6 127.6,27 126,27 128.5,30.9 128.5,33.5 129.9,33.5 129.9,30.9 132.4,27  "
        />
      </g>
      <g>
        <g>
          <path
            class="st1"
            d="M16.2,15.1V0L0,5.4c0,3.6,0.4,6.8,1.1,9.7H16.2z"
          />
          <path
            class="st2"
            d="M33.5,5.4L17.3,0v15.1h15.1C33.1,12.2,33.5,9,33.5,5.4z"
          />
          <path class="st1" d="M17.3,16.2V36c0,0,11-5.1,14.9-19.8H17.3z" />
          <path class="st2" d="M16.2,16.2H1.3C5.2,30.9,16.2,36,16.2,36V16.2z" />
        </g>
        <g id="Cross_5_">
          <path
            fill="var(--white)"
            d="M20,22.4v-1.1h2.4v-2.9h1.2v2.9H26v1.1h-2.4v6h-1.2v-6H20z"
          />
        </g>
        <g id="Dove_5_">
          <path
            fill="var(--white)"
            d="M13,18.2c0.1,1.1,0.4,2.7,0.6,3.8c0.1,0.5-0.2,1-0.3,1.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1l0,0    c0,0,0,0.1-0.1,0.1l0,0l-0.7-1.1l-0.3-0.6c0-0.1-0.1-0.2-0.3-0.4c-0.1-0.1-0.2-0.3-0.4-0.4C11.7,20.2,12.2,19.2,13,18.2z     M10.7,21.1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0.1l0.1,0.1l0,0c0.3,0.3,0.4,0.6,0.4,0.6l0.3,0.6l0.7,1.1v0.1l0,0l0.1,0.1l0,0V24    l0,0l0,0c0,0,0,0,0.1,0l0,0c0.2-0.1,0.3-0.4,0.3-0.4l0,0c0,0,0.1-0.1,0.2-0.1c0.1,0,0.3,0,0.4,0.2c0.1,0,0.4,0.1,0.4,0.1    c-0.1,0-0.3,0.1-0.4,0.2c-0.1,0.1-0.4,0.4-0.4,0.5s-0.2,1.3-1.7,1.7h-0.1c-0.3,0.1-0.7,0.1-1.1,0.2c-0.4,0-0.7,0.1-0.7,0.1    l-0.5,0.4C8,27.3,7,26.6,7,26.6L9.1,26l0.6-0.5l0.1-0.1c0,0,0.1,0,0.1-0.1l0,0c0,0,0.1,0,0.1-0.1l0.1-0.1l0,0c0,0,0,0,0.1,0l0,0    l0,0h-0.1c0,0,0,0-0.1,0c0,0,0,0-0.1-0.1l0,0l-0.1-0.1l0.3-0.1l0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.9-0.1-2.6-1.5-3.7-4.8    C7.9,20.3,9.6,20.7,10.7,21.1z"
          />
        </g>
        <g id="Lamp_5_">
          <g>
            <path
              fill="var(--white)"
              d="M27.1,8.8c-0.2-0.4-0.9-0.8-1.6-0.9l0.1-0.1h0.2V7.5h-0.3l-0.2-0.3h-0.1L25,7.5h-0.3v0.3h0.2L25,7.9     c-0.7,0.1-1.4,0.5-1.6,0.9l0,0H27.1L27.1,8.8z"
            />
          </g>
          <g>
            <path
              fill="var(--white)"
              d="M20.4,7.2c0.1-0.1,0.6-0.4,0.4-0.9c0-0.1-0.1-0.2-0.2-0.4c-0.1-0.1-0.2-0.3-0.3-0.4     c-0.1-0.2-0.2-0.4-0.1-0.7C20,5,19.9,5.3,19.9,5.5c0,0.3,0.1,0.6,0.2,0.8c0,0.2,0,0.5-0.2,0.7c-0.1,0.1-0.2,0.1-0.3,0     c-0.1-0.1-0.1-0.2,0-0.4c0-0.1,0-0.4-0.1-0.6c0,0.2,0,0.3-0.1,0.4s-0.1,0.2-0.2,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.4,0.5,0.8,0.9,1     C19.9,7.7,20,7.4,20.4,7.2z"
            />
          </g>
          <g>
            <path
              fill="var(--white)"
              d="M28.7,7.7l0.3,1h0.4c0,0.1-0.1,0.3-0.3,0.4c-0.5,0-1.2,0-2.2,0s-2.1,0-3.6,0c-0.3,0-0.6,0-0.9,0     c-0.6,0-1.3-0.2-1.6-0.6h-1.3c0,0,0.3,1.2,2.1,1.7c0.7,0.8,1.7,1.3,3,1.4l0,0C24.2,12,24.2,12,23,12.9h4.3     c-1.2-0.8-1.2-0.9-1.6-1.3c1-0.1,2-0.5,2.6-1.1c1.3-0.6,2.6-1.6,3.1-3L28.7,7.7z M29.1,9.8c0,0,0.8-0.9,0.9-1.4h-0.7L29.2,8     l1.6,0C30.5,8.7,29.9,9.4,29.1,9.8z"
            />
          </g>
        </g>
        <g id="Bible_5_">
          <path
            fill="var(--white)"
            d="M8.8,6.2C9.1,6.1,9.3,6,9.5,6v3.8L10,8.5l0.5,1.3v-4c0.8,0,1.3,0.4,1.7,0.8V12c-1.4-0.1-2-0.5-3.4-0.3    C8.8,11.7,8.8,6.2,8.8,6.2z M5,6.6C5.6,6,6.7,5.4,8.5,6.2v5.4C7,11.4,6.4,11.9,5,11.9V6.6z M4.4,7.5l0.3-0.2v5c0.1,0,0.2,0,0.2,0    C6.4,12.2,7,11.7,8.7,12c1.6-0.3,2.3,0.2,3.7,0.3c0.1,0,0.1,0,0.2,0v-5l0.3,0.2v5.1c-1.9,0-2.5-0.6-4.3-0.2    c-1.8-0.3-2.4,0.2-4.3,0.2V7.5H4.4z"
          />
        </g>
      </g>
    </svg>
  );
};
