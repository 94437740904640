import React from "react";
import { useWindowWidth } from "./windowWidth.hook";

const getDevice = (windowWidth) => {
  const defaultParams = {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  };

  if (windowWidth <= 750) {
    return {
      ...defaultParams,
      isMobile: true,
    };
  }
  if (windowWidth <= 1280) {
    return {
      ...defaultParams,
      isTablet: true,
    };
  }
  return {
    ...defaultParams,
    isDesktop: true,
  };
};

export const useDevice = () => {
  const windowWidth = useWindowWidth();
  return getDevice(windowWidth);
};
