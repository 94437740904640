import React from "react";

export const Baylor = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 424 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g
        id="baylor"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="bayloruniversity"
          transform="translate(16.000000, 28.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <g id="Group">
            <path
              d="M28.0327198,103.129132 C28.0327198,115.014511 27.2801636,118.542983 50.9856851,118.542983 C73.1860941,118.542983 79.206544,104.057677 79.206544,94.0293889 C79.206544,83.4439734 78.4539877,62.2731422 40.0736196,62.2731422 C27.0920245,62.2731422 28.0327198,64.5016507 28.0327198,65.9873231 L28.0327198,103.129132 L28.0327198,103.129132 Z M28.0327198,51.1305995 C28.0327198,56.3304528 25.9631902,57.258998 41.0143149,57.258998 C68.4826176,57.258998 69.799591,47.2307095 69.799591,39.4309296 C69.799591,21.6028613 54.5603272,11.5745729 37.6278119,11.5745729 C27.0920245,11.5745729 27.8445808,14.3602085 27.8445808,24.0170789 L27.8445808,51.1305995 L28.0327198,51.1305995 Z M14.8629857,22.5314065 C14.8629857,9.90319144 0,11.5745729 0,8.23181004 C0,5.81759245 2.44580777,6.00330149 4.51533742,6.00330149 C7.90184049,6.00330149 11.2883436,6.74613768 17.8732106,6.74613768 C24.6462168,6.74613768 31.2310838,6.00330149 44.5889571,6.00330149 C74.1267894,6.00330149 81.4642127,21.7885703 81.4642127,34.0453673 C81.4642127,50.9448904 65.6605317,56.3304528 65.6605317,57.444707 C65.6605317,59.8589246 92,60.4160518 92,87.9009904 C92,98.8578241 83.9100204,123 51.3619632,123 C45.3415133,123 35.3701431,122.257164 29.5378323,122.257164 C21.8241309,122.257164 13.9222904,123 6.20858896,123 C5.45603272,123 2.06952965,122.628582 2.06952965,121.14291 C2.06952965,119.471528 4.1390593,118.914401 7.14928425,117.985856 C9.03067485,117.428729 14.8629857,116.685892 14.8629857,103.871968 L14.8629857,22.5314065 L14.8629857,22.5314065 Z"
              id="Shape"
            ></path>
            <path
              d="M142.653521,87.5733844 C131.895775,89.8078093 111.512676,92.6008403 111.512676,107.124602 C111.512676,116.620907 116.419718,120.158746 125.290141,120.158746 C131.329577,120.158746 142.842254,115.503695 142.842254,106.752197 L142.842254,87.5733844 L142.653521,87.5733844 Z M142.653521,70.8151984 C142.653521,62.2499033 135.859155,56.4776392 125.290141,56.4776392 C117.174648,56.4776392 110.757746,57.2224474 110.757746,66.3463488 C110.757746,68.7669756 111.701408,71.3738046 111.701408,73.0496232 C111.701408,74.9116439 111.135211,76.2150583 107.926761,76.2150583 C105.095775,76.2150583 100,73.7944314 100,70.8151984 C100.188732,63.1809136 109.625352,51.6363854 128.121127,52.0087896 C138.501408,52.0087896 154.732394,54.9880226 154.732394,69.1393798 L154.732394,103.40056 C154.732394,108.986622 153.222535,118.855332 161.904225,118.855332 C162.659155,118.855332 164.169014,118.482928 164.735211,118.482928 C165.490141,118.482928 167,118.482928 167,119.60014 C167,123.137979 159.639437,123.696586 157.185915,123.696586 C150.580282,123.696586 146.239437,120.717352 144.16338,115.317492 C137.180282,121.275959 127.932394,125 118.495775,125 C107.549296,125 100.377465,121.089757 100.377465,109.172824 C100.377465,100.421327 103.208451,89.9940113 142.464789,83.476939 L142.464789,70.8151984 L142.653521,70.8151984 Z"
              id="Shape"
            ></path>
            <path
              d="M190.072,158.421055 C189.888,158.990624 186.944,158.990624 184.736,158.990624 C182.528,158.990624 178.664,159.180481 178.664,157.851486 C178.664,154.054356 193.568,141.52383 193.568,128.233877 C193.568,124.246892 191.728,120.449762 190.256,116.842489 L168.728,62.3536844 C167.256,58.5565551 161,58.5565551 161,56.0884211 C161,54.3797129 161.92,54 164.312,54 C167.808,54 171.304,54.7594259 175.72,54.7594259 C181.424,54.7594259 185.288,54 187.864,54 C190.256,54 191.36,54.3797129 191.36,56.2782776 C191.36,59.8855504 181.976,56.468134 181.976,63.1131103 C181.976,65.7711007 183.448,68.4290912 183.08,66.9102395 L182.712,67.2899525 L200.192,113.235217 L212.888,75.0740675 C214.912,68.9986606 214.912,67.4798089 214.912,66.3406701 C214.912,57.2275599 204.424,59.6956939 204.424,56.0884211 C204.424,54.3797129 206.448,54.1898565 209.392,54.1898565 C211.416,54.1898565 213.992,54.9492823 218.592,54.9492823 C222.272,54.9492823 225.216,54.1898565 226.872,54.1898565 C228.896,54.1898565 230,54.9492823 230,56.2782776 C230,60.0754068 224.48,55.8985646 221.168,66.1508137 L190.072,158.421055 Z"
              id="Path"
            ></path>
            <path
              d="M251.869347,112.378378 C251.869347,123.918919 266,119.189189 266,123.72973 C266,125.810811 264.663317,126 262.180905,126 C259.889447,126 254.542714,125.243243 246.522613,125.243243 C238.120603,125.243243 234.874372,126 233.346734,126 C232.01005,126 231.246231,125.243243 231.246231,123.72973 C231.246231,121.27027 239.648241,121.648649 239.648241,114.459459 L239.648241,19.4864865 C239.648241,9.45945946 228,13.8108108 228,10.2162162 C228,9.27027027 228.763819,8.51351351 229.527638,8.32432432 C242.703518,5.2972973 245.949749,0 249.959799,0 C250.914573,0 252.060302,0.945945946 252.060302,2.64864865 L252.060302,112.378378 L251.869347,112.378378 Z"
              id="Path"
            ></path>
            <path
              d="M327.424552,91.5805627 C327.424552,74.4040921 320.329923,55.7340153 300.539642,55.7340153 C284.296675,55.7340153 277.575448,70.1099744 277.575448,84.112532 C277.575448,100.355499 285.230179,121.639386 304.647059,121.639386 C318.649616,121.639386 327.424552,112.117647 327.424552,91.5805627 M266,89.9002558 C266,69.5498721 282.056266,52 302.966752,52 C315.662404,52 339,58.1611253 339,88.9667519 C339,109.317136 323.503836,125 303.153453,125 C281.496164,125 266,112.304348 266,89.9002558"
              id="Shape"
            ></path>
            <path
              d="M365.229391,110.3257 C365.229391,125.743003 379.953405,117.755725 379.953405,123.142494 C379.953405,124.628499 379.207885,125 377.344086,125 C375.107527,125 371.007168,124.256997 361.688172,124.256997 C350.505376,124.256997 347.523297,125 346.405018,125 C345.100358,125 344.541219,124.442748 344.541219,123.513995 C344.541219,119.984733 352.928315,120.727735 352.928315,113.854962 L352.928315,71.1323155 C352.928315,61.2875318 341,65.5597964 341,62.0305344 C341,61.1017812 342.304659,60.3587786 343.236559,60.173028 C356.096774,57.2010178 359.265233,52 363.179211,52 C364.111111,52 365.229391,52.9287532 365.229391,54.6005089 L365.229391,67.0458015 L365.602151,67.0458015 C369.143369,62.216285 375.107527,53.3002545 382.003584,53.3002545 C385.544803,53.3002545 393,57.5725191 393,60.7302799 C393,63.1450382 389.086022,68.1603053 387.035842,68.1603053 C383.867384,68.1603053 382.003584,64.4452926 376.039427,64.4452926 C369.329749,64.4452926 365.229391,72.0610687 365.229391,74.2900763 L365.229391,110.3257 L365.229391,110.3257 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
