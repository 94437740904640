import { SVGIcons } from "components/svg-wrapper/SvgWrapper.enum";
import linksService from "services/links.service";

export const menuCallsToAction = [
  {
    label: "Submit An Inquiry",
    icon: SVGIcons.Document,
    link: linksService.inquiryForm(),
    newTab: true,
  },
  {
    label: "Schedule A Visit",
    icon: SVGIcons.Visit,
    link: linksService.scheduleVisit(),
    newTab: true,
  },
  {
    label: "View Upcoming Events",
    icon: SVGIcons.Calendar,
    link: linksService.upcomingEventsHighSchool(),
    newTab: true,
  },
  {
    label: "Email Us",
    icon: SVGIcons.Envelope,
    link: linksService.email(),
  },
];

export const menuPages = [
  {
    label: "Our Story",
    icon: SVGIcons.Book,
    link: linksService.ourStory(),
  },
  {
    label: "Admissions",
    icon: SVGIcons.Application,
    link: linksService.admissions(),
  },
  {
    label: "High School",
    icon: SVGIcons.LargeSchool,
    link: linksService.highSchool(),
  },
  {
    label: "Middle School",
    icon: SVGIcons.SmallSchool,
    link: linksService.middleSchool(),
  },
];
