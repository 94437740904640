import { menuCallsToAction, menuPages } from "./Menu.config";
import { MENU_DELAY, STAGGER_CHILDREN_DURATION } from "./Menu.constants";

export const menuVariants = {
  closed: {
    opacity: 0,
    transition: {
      duration: 0.05,
    },
  },
  open: {
    opacity: 1,
    transition: {
      delay: MENU_DELAY,
      staggerChildren: STAGGER_CHILDREN_DURATION,
      duration:
        (menuCallsToAction.length + menuPages.length) *
        STAGGER_CHILDREN_DURATION,
    },
  },
};

export const menuItemVariants = {
  closed: { opacity: 0 },
  open: { opacity: 1 },
};
