import React from "react";

export const UNC = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      viewBox="0 0 549 187"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="unc" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="unc-logo"
          transform="translate(12.000000, 22.000000)"
          fill={color}
          fillOpacity={opacity}
          fillRule="nonzero"
        >
          <g id="Group">
            <path
              d="M27.2991892,7.83865141 L25.4252359,7.83865141 C24.8658012,3.95845876 24.1137743,3.09753161 21.4877941,3.09753161 L16.9909175,3.09753161 L16.9909175,19.205298 C16.9909175,22.615894 17.4678127,23.1607465 21.3685703,23.1607465 L21.3685703,25 L5.92756192,25 L5.92756192,23.1607465 C9.8252625,23.1607465 10.3052147,22.6128838 10.3052147,19.205298 L10.3052147,3.09753161 L5.56989057,3.09753161 C3.69899428,3.09753161 2.42727394,3.84406984 1.86783926,7.83865141 L0,7.83865141 L0.755083957,0 L2.46395818,0 C2.62598024,0.234798314 2.78494528,0.394340759 3.09981835,0.511739916 C3.3810642,0.586995786 3.81821807,0.626128838 4.45407824,0.626128838 L22.3651588,0.626128838 C24.2329981,0.626128838 24.4714457,0.586995786 24.8688583,0 L26.5838465,0 L27.2991892,7.83865141 Z"
              id="Path"
            ></path>
            <path
              d="M58.8506934,11.2462372 L58.8506934,6.34858519 C58.8506934,3.25406382 58.2148332,2.54665864 55.5093704,2.47140277 L55.5093704,0.629139073 L69,0.629139073 L69,2.47140277 C66.0958309,2.54665864 65.5363963,3.25406382 65.5363963,5.83684527 L65.5363963,19.2805539 C65.5363963,22.0288983 65.8543263,23.1186033 69,23.1607465 L69,25 L55.5093704,25 L55.5093704,23.1607465 C58.0956094,23.0433474 58.8506934,22.4172185 58.8506934,19.3558098 L58.8506934,13.6785069 L46.91303,13.6785069 L46.91303,19.4762191 C46.91303,21.8603251 47.1942758,23.0794702 50.3766337,23.1607465 L50.3766337,25 L36.7270391,25 L36.7270391,23.1607465 C39.4722431,23.0433474 40.2242701,22.5707405 40.2242701,19.3558098 L40.2242701,6.2372065 C40.2242701,3.09753161 39.3499623,2.47140277 36.7270391,2.47140277 L36.7270391,0.629139073 L50.3766337,0.629139073 L50.3766337,2.47140277 C47.7078552,2.62793498 46.91303,2.98013245 46.91303,6.19506321 L46.91303,11.2462372 L58.8506934,11.2462372 L58.8506934,11.2462372 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(78.000000, 1.000000)">
            <path
              d="M23.0453301,0.0030102348 L23.40432,5.83985551 L21.5393976,5.83985551 C21.18345,3.17880795 20.5871615,2.43226972 18.6492242,2.43226972 L10.8913903,2.43226972 L10.8913903,10.9753161 L15.9993915,10.9753161 C18.8135078,10.9753161 19.5619106,10.3883203 19.5619106,7.99518362 L21.4237907,7.99518362 L21.4237907,16.1860325 L19.5619106,16.1860325 C19.5619106,13.9945816 18.9291147,13.4045756 16.035899,13.4045756 L10.8913903,13.4045756 L10.8913903,19.7140277 C10.8913903,21.7910897 11.1286888,21.9446117 13.2643748,21.9446117 L17.8612717,21.9446117 C21.3081838,21.9446117 22.2147855,20.4605659 22.8110739,17.871764 L24.6699118,17.871764 L24.1527228,24.3768814 L0,24.3768814 L0,22.5376279 C3.60206876,22.5376279 4.23486462,21.7158338 4.23486462,18.9343769 L4.23486462,5.45153522 C4.23486462,2.12522577 3.08792212,1.8512944 0.270763614,1.8512944 L0.270763614,0.00903070439 L23.0453301,0.00903070439 L23.0453301,0.0030102348 Z"
              id="Path"
            ></path>
            <path
              d="M80,0.0030102348 L80,1.84527393 C76.6717371,1.84527393 76.0085184,2.43226972 76.0085184,5.56893438 L76.0085184,14.2233594 C76.0085184,22.0620108 71.1043505,25 64.3261333,25 C57.1128689,25 52.4034074,21.8272125 52.4034074,16.0295003 L52.4034074,5.64720048 C52.4034074,2.98013245 51.8466687,1.84527393 49.0751445,1.84527393 L49.0751445,0.0030102348 L62.5829023,0.0030102348 L62.5829023,1.84527393 C59.7292364,1.84527393 59.0508062,2.66706803 59.0508062,5.29500301 L59.0508062,15.7164359 C59.0508062,19.3558098 61.0343779,21.6315473 65.9142075,21.6315473 C71.1773654,21.6315473 73.1944022,18.8500903 73.1944022,13.871162 L73.1944022,5.60806743 C73.1944022,2.58278146 72.4459994,1.8422637 69.2363858,1.8422637 L69.2363858,0 L80,0 L80,0.0030102348 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(165.000000, 1.000000)">
            <path
              d="M31.6068814,0 L31.6068814,1.71910112 C28.7048091,1.71910112 27.9800653,2.4494382 27.9800653,5.01123596 L27.9800653,23 L24.7992454,23 L6.53508278,5.51966292 L6.53508278,17.5870787 C6.53508278,20.002809 6.93771821,21.0252809 10.3632166,21.0252809 L10.3632166,22.741573 L0,22.741573 L0,21.0252809 C2.82773961,21.0252809 3.67017682,20.4747191 3.67017682,17.4044944 L3.67017682,2.66853933 C3.1901115,2.23033708 2.22068927,1.71910112 0.288039194,1.71910112 L0.288039194,0 L9.79642978,0 L25.1151594,14.7696629 L25.1151594,4.97752809 C25.1151594,2.74438202 24.6753576,1.72191011 21.4109134,1.72191011 L21.4109134,0.00280898876 L31.6068814,0.00280898876 L31.6068814,0 Z"
              id="Path"
            ></path>
            <path
              d="M41.0873972,22.741573 L41.0873972,21.0252809 C43.9461088,21.0252809 44.5500619,20.0730337 44.5500619,17.7331461 L44.5500619,5.23314607 C44.5500619,2.66853933 43.9894695,1.71910112 41.0873972,1.71910112 L41.0873972,0 L55,0 L55,1.71910112 C51.7665278,1.71910112 51.3236288,2.66853933 51.3236288,5.23314607 L51.3236288,17.5140449 C51.3236288,19.7808989 51.56521,21.0252809 55,21.0252809 L55,22.741573 L41.0873972,22.741573 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(226.000000, 1.000000)">
            <path
              d="M30.3520089,1.84248555 C27.459745,1.84248555 26.7435363,2.39041426 24.9941586,6.23494701 L16.3844161,25 L13.4891045,25 L5.16279779,6.73771676 C3.0903642,2.19171484 2.66673439,1.84248555 0,1.84248555 L0,0 L14.9580942,0 L14.9580942,1.84248555 C12.0231625,1.84248555 10.9168487,2.39041426 10.9168487,3.41100674 C10.9168487,3.91678709 11.1484736,4.66341522 11.6757251,5.7984104 L16.9451923,17.2055636 L21.5075938,7.13210501 C22.6139077,4.66642582 22.8942957,3.95893545 22.8942957,3.29058285 C22.8942957,2.27601156 21.8641743,1.76421002 19.4016356,1.84549615 L19.4016356,0.0030105973 L30.3581043,0.0030105973 L30.3581043,1.84248555 L30.3520089,1.84248555 Z"
              id="Path"
            ></path>
            <path
              d="M58.3786255,0 L58.7321583,5.83754817 L56.8700157,5.83754817 C56.5134353,3.17618015 55.9191345,2.42955202 53.9716564,2.42955202 L46.1939351,2.42955202 L46.1939351,10.9736272 L51.3171128,10.9736272 C54.1301366,10.9736272 54.8829177,10.3865607 54.8829177,7.99313584 L56.7511556,7.99313584 L56.7511556,16.1849711 L54.8829177,16.1849711 C54.8829177,13.9932563 54.2429014,13.4031792 51.3597806,13.4031792 L46.1969828,13.4031792 L46.1969828,19.7133911 C46.1969828,21.7907033 46.4316554,21.9442437 48.5833291,21.9442437 L53.1792553,21.9442437 C56.6322954,21.9442437 57.5466044,20.4600193 58.1409052,17.8709056 L60,17.8709056 L59.4971301,24.3768064 L35.2862295,24.3768064 L35.2862295,22.5373314 C38.9038452,22.5373314 39.5408137,21.7154383 39.5408137,18.9336464 L39.5408137,5.44918112 C39.5408137,2.1224711 38.3826891,1.84850674 35.5666176,1.84850674 L35.5666176,0.00602119461 L58.3786255,0.00602119461 L58.3786255,0 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M328,25 L323.286257,25 C319.841485,25 317.692183,24.3439021 316.046348,22.6813918 C313.555513,20.1727823 311.291386,16.0758817 309.454174,14.4519653 L307.419698,14.4519653 L307.419698,19.6294977 C307.419698,22.4913906 308.535568,22.8387365 311.526926,22.8387365 L311.526926,24.6526541 L297,24.6526541 L297,22.8387365 C300.223953,22.8387365 300.986513,22.2568579 300.986513,19.4365277 L300.986513,5.95238095 C300.986513,3.05189407 300.297559,2.43439021 297,2.43439021 L297,0.617503859 C299.33479,0.463127895 300.912907,0.344377152 303.977871,0.190001188 C306.383322,0.0771879824 308.76522,0 310.063634,0 C316.196505,0 321.793523,1.04500653 321.793523,6.99441872 C321.793523,9.93349958 319.912147,12.0947631 316.317219,13.2941456 C318.76389,15.2238451 320.180074,18.5874599 322.635578,21.0604441 C324.316744,22.7585797 325.936081,23.1415509 327.997056,23.1831136 L327.997056,25 L328,25 Z M307.419698,12.5549222 C312.984329,12.6321102 314.971697,10.8924118 314.971697,6.91426196 C314.971697,3.85939912 313.01966,1.85251158 309.301073,1.85251158 L307.416754,1.85251158 L307.416754,12.5549222 L307.419698,12.5549222 Z"
            id="Shape"
          ></path>
          <g id="Group" transform="translate(335.000000, 0.000000)">
            <path
              d="M1.81164517,17.1669408 C2.89863228,20.8382284 5.44681516,22.9793233 8.657288,22.9793233 C11.0183665,22.9793233 12.6429074,21.5636748 12.6429074,19.5782425 C12.6429074,14.5294878 0.347479484,14.0331297 0.347479484,6.61419173 C0.347479484,3.90037594 2.23634232,0 8.58007034,0 C11.4400938,0 13.4536928,0.76656485 14.573349,0.76656485 C15.001016,0.76656485 15.2296991,0.649083647 15.4227433,0.270206767 L16.7027745,0.270206767 L17.3531848,7.07236842 L15.5415397,7.07236842 C14.4961313,3.78289474 11.7905432,2.02361372 9.0077374,2.02361372 C7.00304807,2.02361372 5.6042204,3.01926692 5.6042204,4.43197838 C5.6042204,8.40871711 18.5233294,9.51891447 18.5233294,17.7367246 C18.5233294,22.0600329 14.8465807,25 9.48292302,25 C6.72684642,25 3.91137163,24.1600094 3.25502149,24.1600094 C2.63134037,24.1600094 2.36107855,24.5036419 2.28683079,24.7327303 L1.04837827,24.7327303 L0,17.1640038 L1.81164517,17.1640038 L1.81164517,17.1669408 Z"
              id="Path"
            ></path>
            <path
              d="M27.9973427,24.3920348 L27.9973427,22.5975094 C30.7415397,22.5975094 31.3177022,21.6018562 31.3177022,19.1553102 L31.3177022,6.08552632 C31.3177022,3.40401786 30.7801485,2.41130169 27.9973427,2.41130169 L27.9973427,0.613839286 L41.3352091,0.613839286 L41.3352091,2.41130169 C38.2405627,2.41130169 37.8158656,3.40401786 37.8158656,6.08552632 L37.8158656,18.9262218 C37.8158656,21.2964051 38.0445487,22.5975094 41.3352091,22.5975094 L41.3352091,24.3920348 L27.9973427,24.3920348 Z"
              id="Path"
            ></path>
            <path
              d="M76,7.64802632 L74.1883548,7.64802632 C73.6448613,3.86219455 72.9023837,3.02220395 70.3571708,3.02220395 L65.988433,3.02220395 L65.988433,18.7382519 C65.988433,22.065907 66.4547089,22.5975094 70.2472841,22.5975094 L70.2472841,24.3920348 L55.2462681,24.3920348 L55.2462681,22.5975094 C59.0329035,22.5975094 59.4932395,22.0629699 59.4932395,18.7382519 L59.4932395,3.02220395 L54.8958187,3.02220395 C53.0812036,3.02220395 51.8427511,3.75058741 51.2992575,7.64802632 L49.4816725,7.64802632 L50.2211801,0 L51.8813599,0 C52.044705,0.229088346 52.1902306,0.38475094 52.4931614,0.499295113 C52.7723329,0.572720865 53.1910903,0.610902256 53.8118015,0.610902256 L71.209535,0.610902256 C73.0300899,0.610902256 73.258773,0.572720865 73.6448613,0 L75.305041,0 L76,7.64802632 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M447.997076,2.73863636 C445.406433,2.73863636 444.763158,3.25568182 442.704678,6.32670455 L437.108187,14.6818182 L437.108187,18.7556818 C437.108187,21.4147727 437.48538,22.2642045 440.883041,22.2642045 L440.883041,24 L426.909357,24 L426.909357,22.2642045 C430.260234,22.2642045 430.71345,21.375 430.71345,18.7130682 L430.71345,14.90625 L423.707602,5.69886364 C421.885965,3.29261364 421.163743,2.73863636 419,2.73863636 L419,1 L432.701754,1 L432.701754,2.73863636 C430.836257,2.73863636 430.111111,2.96022727 430.111111,3.66193182 C430.111111,3.96022727 430.374269,4.44034091 430.716374,4.88068182 L436.04386,11.9090909 L439.885965,6.06818182 C440.570175,4.99147727 440.885965,4.36363636 440.885965,3.92329545 C440.885965,3.06818182 440.236842,2.73863636 438.371345,2.73863636 L438.371345,1 L448,1 L448,2.73863636 L447.997076,2.73863636 Z"
            id="Path"
          ></path>
          <g id="Group" transform="translate(0.000000, 55.000000)">
            <path
              d="M12.4623204,11.4669541 C15.4510497,11.4669541 17.3816575,14.0944168 17.3816575,18.1270538 C17.3816575,25.9637202 9.8881768,30.9443164 5.01524862,30.9443164 C2.06983425,30.9443164 0,28.2680842 0,24.4671028 C0,17.8527247 6.16618785,11.4669541 12.4623204,11.4669541 Z M7.86165746,29.0362055 C9.60972376,29.0362055 12.1374586,24.5585458 12.1374586,18.9408966 C12.1374586,15.95071 10.9401105,13.3232473 9.60972376,13.3232473 C7.72552486,13.3232473 5.33392265,17.8984462 5.33392265,23.3758828 C5.33392265,26.6403985 6.3920442,29.0362055 7.86165746,29.0362055 Z"
              id="Shape"
            ></path>
            <path
              d="M28.6187845,28.9051372 C28.0216575,32.3037692 26.920221,35.2939558 24.0676243,38.1012564 C22.2298343,39.9148762 19.9743646,41 16.8525967,41 C14.0464088,41 12.0693923,39.8691547 12.0693923,38.2353728 C12.0693923,37.058806 13.3100552,35.9706342 14.6930387,35.9706342 C17.4961326,35.9706342 17.400221,39.3692662 19.5659669,39.3692662 C21.2181215,39.3692662 22.2762431,37.4672515 22.7867403,34.4313434 L26.2333702,13.9999257 L22.7867403,13.9999257 L23.0125967,12.91785 C24.1171271,12.91785 25.7723757,12.463683 26.6448619,11.9150249 C27.6101657,5.56887964 31.3785635,0 37.680884,0 C40.440663,0 42,0.95100736 42,2.57869303 C42,3.89547246 40.8985635,4.94097093 39.518674,4.94097093 C36.9414365,4.94097093 35.9792265,1.81666791 34.4167956,1.81666791 C33.3122652,1.81666791 32.9440884,3.48702699 32.3005525,7.42822095 L31.5641989,11.9119768 L34.8777901,11.9119768 L34.4167956,13.9968776 L31.1527072,13.9968776 L28.6187845,28.9051372 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(59.000000, 59.000000)">
            <path
              d="M31.1270183,0.60782241 L31.1270183,2.40779892 C28.269286,2.40779892 27.5556154,3.16831102 27.5556154,5.84918957 L27.5556154,24.6505755 L24.4172946,24.6505755 L6.43523502,6.3777308 L6.43523502,18.9981207 C6.43523502,21.5175006 6.82866882,22.5863284 10.2048798,22.5863284 L10.2048798,24.3892412 L0,24.3892412 L0,22.5892647 C2.77843559,22.5892647 3.61410118,22.0196148 3.61410118,18.8072586 L3.61410118,3.40321823 C3.13832078,2.94808551 2.18980983,2.41073526 0.280588446,2.41073526 L0.280588446,0.61075875 L9.64980266,0.61075875 L24.7344815,16.0529716 L24.7344815,5.81688983 C24.7344815,3.47956307 24.2983495,2.4136716 21.0837818,2.4136716 L21.0837818,0.61369509 L31.1270183,0.61369509 L31.1270183,0.60782241 Z"
              id="Path"
            ></path>
            <path
              d="M52.5981342,25 C41.4416936,25 37.9861859,17.9674654 37.9861859,12.6526897 C37.9861859,6.22797745 42.7500897,0 53.6289917,0 C64.6664873,0 68,7.03547099 68,12.2298567 C68,17.3126615 64.6664873,25 52.5981342,25 Z M52.3998924,1.83521259 C48.8284894,1.83521259 45.0557948,4.27824759 45.0557948,10.8908856 C45.0557948,18.1142824 48.6668461,23.1618511 53.8729817,23.1618511 C57.8012199,23.1618511 60.9364909,20.2196382 60.9364909,14.0650693 C60.9364909,6.84167254 57.4443846,1.83521259 52.3998924,1.83521259 Z"
              id="Shape"
            ></path>
          </g>
          <g id="Group" transform="translate(135.000000, 59.000000)">
            <path
              d="M31.8462868,25 L27.0073448,25 C23.4537468,25 21.2611012,24.3410899 19.5614229,22.6819423 C17.0028323,20.1679924 14.6861889,16.0750326 12.7869041,14.457438 L10.7061591,14.457438 L10.7061591,19.6307729 C10.7061591,22.4860501 11.8493591,22.8333135 14.9220873,22.8333135 L14.9220873,24.6557046 L0,24.6557046 L0,22.8333135 C3.30257789,22.8333135 4.08890596,22.2575092 4.08890596,19.4348807 L4.08890596,5.95096759 C4.08890596,3.05116942 3.38423503,2.43381218 0,2.43381218 L0,0.614389173 C2.39830061,0.465985991 4.01027315,0.347263445 7.1616341,0.192924136 C9.63856752,0.0742015909 12.0852575,0 13.4189909,0 C19.7217128,0 25.4679564,1.04179034 25.4679564,6.99275792 C25.4679564,9.9252048 23.5384283,12.0889232 19.8366377,13.290989 C22.3559119,15.2202303 23.8136431,18.5800784 26.3359416,21.0554434 C28.062839,22.7531758 29.7171523,23.1419922 31.8402381,23.1776089 L31.8402381,25 L31.8462868,25 Z M10.7091834,12.5578772 C16.4161106,12.6350469 18.4666123,10.8927935 18.4666123,6.91558827 C18.4666123,3.86441885 16.455427,1.85800784 12.6326629,1.85800784 L10.7061591,1.85800784 L10.7061591,12.5578772 L10.7091834,12.5578772 Z"
              id="Shape"
            ></path>
            <path
              d="M63,7.72883771 L61.152129,7.72883771 C60.5956507,3.90003562 59.8516634,3.05413748 57.2567808,3.05413748 L52.7989055,3.05413748 L52.7989055,18.936246 C52.7989055,22.293126 53.2707023,22.8333135 57.1297585,22.8333135 L57.1297585,24.6557046 L41.8628966,24.6557046 L41.8628966,22.8333135 C45.7159042,22.8333135 46.1846767,22.2901579 46.1846767,18.936246 L46.1846767,3.05413748 L41.5030003,3.05413748 C39.652105,3.05413748 38.3939801,3.7872492 37.8465748,7.72883771 L35.9987039,7.72883771 L36.7396668,0 L38.4302722,0 C38.5935865,0.231508964 38.7478278,0.382880209 39.062359,0.504570818 C39.3405981,0.578772409 39.7791273,0.614389173 40.3960924,0.614389173 L58.1187173,0.614389173 C59.9635639,0.614389173 60.2024867,0.578772409 60.5956507,0 L62.2862561,0 L63,7.72883771 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(204.000000, 59.000000)">
            <path
              d="M22.0748726,10.9688784 L22.0748726,6.19201409 C22.0748726,3.17087493 21.4344909,2.48678802 18.7357394,2.41045214 L18.7357394,0.610687023 L32.1868049,0.610687023 L32.1868049,2.41045214 C29.2928894,2.48678802 28.7348425,3.17087493 28.7348425,5.69876688 L28.7348425,18.8050499 C28.7348425,21.4826776 29.0489345,22.5513799 32.1868049,22.5866119 L32.1868049,24.389313 L18.7357394,24.389313 L18.7357394,22.5866119 C21.3155629,22.475044 22.0748726,21.864357 22.0748726,18.8813858 L22.0748726,13.3382267 L10.1576738,13.3382267 L10.1576738,18.9988256 C10.1576738,21.3270699 10.438222,22.510276 13.6157351,22.5866119 L13.6157351,24.389313 L0,24.389313 L0,22.5866119 C2.73534477,22.475044 3.49160508,22.0170288 3.49160508,18.8813858 L3.49160508,6.07457428 C3.49160508,3.01820317 2.61641673,2.40751615 0,2.40751615 L0,0.607751028 L13.6157351,0.607751028 L13.6157351,2.40751615 C10.9535768,2.55725191 10.1576738,2.90663535 10.1576738,6.03640634 L10.1576738,10.9659425 L22.0748726,10.9659425 L22.0748726,10.9688784 Z"
              id="Path"
            ></path>
            <path
              d="M85,16.890781 C84.6828586,19.0722255 83.7314343,21.899589 82.5756978,24.4979448 L82.4659181,24.7680564 C82.0999856,24.6506166 81.7066083,24.5772167 81.3101815,24.5390487 C80.9564469,24.4597769 80.5142785,24.4597769 80.0416158,24.4597769 C78.9651647,24.4597769 77.8185765,24.5772167 76.586604,24.7328244 C75.3912248,24.8443922 74.1226591,25 72.7351654,25 C67.4108488,25 63.7637225,23.2795068 61.5010404,20.9101585 C59.2322595,18.5349383 58.2472914,15.5607751 58.2472914,12.9183793 C58.2472914,9.09864944 59.8360479,5.92483852 62.6110354,3.59659425 C65.352479,1.30064592 69.283203,0 73.8878525,0 C78.1265696,0 80.4715864,1.14797416 81.4626534,1.14797416 C81.8224869,1.14797416 82.0237497,0.995302408 82.179271,0.607751028 L83.6490995,0.607751028 L83.9997847,8.60246624 L82.1365789,8.60246624 C81.7005094,6.65002936 80.7094425,5.04697592 79.3219488,3.9371697 C77.9314056,2.82736348 76.1810289,2.21667645 74.1958456,2.21667645 C68.92032,2.21667645 65.3067375,6.37991779 65.3067375,12.3076923 C65.3067375,18.5349383 69.0727918,22.7803876 74.6349645,22.7803876 C78.3674751,22.7803876 81.2278467,20.8308867 83.1337447,16.890781 L85,16.890781 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(295.000000, 59.000000)">
            <path
              d="M29.5827762,24.6557046 L15.1473102,24.6557046 L15.1473102,22.8333135 C17.854151,22.8333135 18.8104051,22.527603 18.8104051,21.5214294 C18.8104051,21.0168586 18.5262785,20.0967589 17.9671906,18.6631841 L17.0506531,16.3866793 L8.30994023,16.3866793 L7.59198583,18.4287071 C7.03595307,20.0136531 6.71822006,21.0940283 6.71822006,21.408643 C6.71822006,22.4504333 8.03192384,22.8303455 10.2193934,22.8303455 L10.2193934,24.6527366 L0,24.6527366 L0,22.8333135 C2.94208545,22.8333135 3.29953509,22.1773715 4.73238875,18.1645494 L10.9373478,0.854802327 L10.5798982,0.00296806364 L17.7350011,0.00296806364 L24.9725924,18.2773359 C26.4054461,21.8686929 27.282267,22.7205271 29.5858313,22.8333135 L29.5858313,24.6557046 L29.5827762,24.6557046 Z M12.4832411,4.82903953 L9.183706,13.9855158 L16.1463361,13.9855158 L12.4832411,4.82903953 Z"
              id="Shape"
            ></path>
            <path
              d="M69,25 L64.1026345,25 C60.5220279,25 58.2978968,24.3410899 56.5900819,22.6819423 C54.002391,20.1679924 51.6621652,16.0750326 49.7466017,14.457438 L47.6446757,14.457438 L47.6446757,19.6307729 C47.6446757,22.4860501 48.7903476,22.8333135 51.8943547,22.8333135 L51.8943547,24.6557046 L36.8203675,24.6557046 L36.8203675,22.8333135 C40.1565641,22.8333135 40.9570069,22.2575092 40.9570069,19.4348807 L40.9570069,5.95096759 C40.9570069,3.05116942 40.2451627,2.43381218 36.8203675,2.43381218 L36.8203675,0.614389173 C39.2491919,0.465985991 40.8806287,0.347263445 44.0640691,0.192924136 C46.5692716,0.0742015909 49.0378127,0 50.388178,0 C56.7520035,0 62.5475758,1.04179034 62.5475758,6.99275792 C62.5475758,9.9252048 60.5953509,12.0889232 56.8680983,13.290989 C59.4160726,15.2202303 60.8764224,18.5800784 63.4243967,21.0554434 C65.1749834,22.7531758 66.8491919,23.1419922 68.9938897,23.1776089 L68.9938897,25 L69,25 Z M47.6477308,12.5578772 C53.4096967,12.6350469 55.4810715,10.8927935 55.4810715,6.91558827 C55.4810715,3.86441885 53.4555236,1.85800784 49.5907903,1.85800784 L47.6446757,1.85800784 L47.6446757,12.5578772 L47.6477308,12.5578772 Z"
              id="Shape"
            ></path>
          </g>
          <g id="Group" transform="translate(369.000000, 59.000000)">
            <path
              d="M14.3651224,25 C3.40613212,25 0,17.9674654 0,12.6526897 C0,6.22797745 4.69242673,0 15.3785666,0 C26.2326144,0 29.5098172,7.03547099 29.5098172,12.2298567 C29.5098172,17.3126615 26.2326144,25 14.3651224,25 Z M14.1672309,1.83521259 C10.6591547,1.83521259 6.94719025,4.27824759 6.94719025,10.8908856 C6.94719025,18.1142824 10.5062385,23.1618511 15.6154367,23.1618511 C19.4893123,23.1618511 22.5656253,20.2196382 22.5656253,14.0650693 C22.5626269,6.84167254 19.1265113,1.83521259 14.1672309,1.83521259 Z"
              id="Shape"
            ></path>
            <path
              d="M51.8055905,0.60782241 L51.8055905,2.40779892 C48.4954057,2.40779892 47.8357675,3.01855767 47.8357675,6.03711534 L47.8357675,19.3035001 C47.8357675,21.6320179 48.1086179,22.0166784 50.7171873,22.0166784 L53.6885579,22.0166784 C56.066254,22.0166784 56.8878035,21.9403336 60.1620079,17.4712239 L62,17.4712239 L59.0256311,24.3892412 L37.4494632,24.3892412 L37.4494632,22.5892647 C40.8466003,22.5892647 41.2723668,21.5997181 41.2723668,18.8836035 L41.2723668,6.04005168 C41.2723668,3.28870096 40.8436019,2.41073526 37.4494632,2.41073526 L37.4494632,0.61075875 L51.8055905,0.61075875 L51.8055905,0.60782241 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(436.000000, 61.000000)">
            <path
              d="M0,22.752809 L0,21.0280899 C2.8249764,21.0280899 3.42051197,20.0814607 3.42051197,17.738764 L3.42051197,5.23033708 C3.42051197,2.67134831 2.86162474,1.72191011 0,1.72191011 L0,0 L13.7156422,0 L13.7156422,1.72191011 C10.5333444,1.72191011 10.0935643,2.67134831 10.0935643,5.23033708 L10.0935643,17.5168539 C10.0935643,19.7865169 10.3348326,21.0280899 13.7156422,21.0280899 L13.7156422,22.752809 L0,22.752809 L0,22.752809 Z"
              id="Path"
            ></path>
            <path
              d="M55,0 L55,1.72191011 C52.1353212,1.72191011 51.4267866,2.4494382 51.4267866,5.01404494 L51.4267866,23 L48.2841912,23 L30.283747,5.51966292 L30.283747,17.5926966 C30.283747,20.002809 30.6685546,21.0252809 34.0493642,21.0252809 L34.0493642,22.75 L23.8397468,22.75 L23.8397468,21.0280899 C26.6158587,21.0280899 27.4557166,20.4831461 27.4557166,17.4101124 L27.4557166,2.6741573 C26.9762341,2.23876404 26.0203232,1.7247191 24.1115553,1.7247191 L24.1115553,0.00280898876 L33.487423,0.00280898876 L48.6048642,14.7752809 L48.6048642,4.98314607 C48.6048642,2.74719101 48.1681382,1.72752809 44.94003,1.72752809 L44.94003,0.00561797753 L55,0.00561797753 L55,0 Z"
              id="Path"
            ></path>
          </g>
          <path
            d="M523.997006,84 L509.848838,84 L509.848838,82.1519384 C512.495818,82.1519384 513.436035,81.8419215 513.436035,80.8215748 C513.436035,80.3098965 513.154569,79.376836 512.612597,77.9230677 L511.720289,75.6144955 L503.144553,75.6144955 L502.440888,77.6852877 C501.892927,79.2925596 501.584512,80.3881531 501.584512,80.7071996 C501.584512,81.7636648 502.87207,82.1489285 505.01301,82.1489285 L505.01301,83.9969901 L495,83.9969901 L495,82.1519384 C497.886526,82.1519384 498.236861,81.4867566 499.635209,77.4174091 L505.722664,59.8638334 L505.363345,59 L512.385028,59 L519.475581,77.5317843 C520.876923,81.1737298 521.745276,82.0375632 524,82.1519384 L524,84 L523.997006,84 Z M507.243779,63.8940525 L504.003924,73.1795088 L510.81301,73.1795088 L507.243779,63.8940525 Z"
            id="Shape"
          ></path>
          <g id="Group" transform="translate(0.000000, 119.000000)">
            <path
              d="M13.5796086,20.1180192 C13.3737287,21.1065823 13.2863003,21.8274729 13.7488249,21.8274729 C14.2085292,21.8274729 15.7596787,20.2974814 16.6396035,19.1598735 L16.6396035,20.6564059 C14.5018374,23.0958754 12.2371592,24.9969583 10.6465259,24.9969583 C9.55508076,24.9969583 8.96846423,24.0023117 8.96846423,22.2837328 C8.96846423,21.4655068 8.96846423,21.0609563 9.2617725,19.4336294 L9.17716434,19.4336294 C6.53456969,22.3719431 4.14861978,24.9939165 2.89077857,24.9939165 C1.92624562,24.9939165 0,23.7285558 0,20.6107799 C0,17.6694245 0.964532946,13.9645942 4.56884027,10.07726 C8.00393129,6.41805572 10.9398342,5.55724541 16.3462952,5.55724541 L13.5796086,20.1180192 Z M11.3177506,8.32218031 C9.17998462,8.32218031 7.79523118,9.00048668 6.49790616,10.3996837 C5.07366892,11.9357586 3.90325613,15.1478282 3.90325613,17.7241757 C3.90325613,19.5765908 4.57166054,20.8358681 5.62080164,20.8358681 C6.58533459,20.8358681 7.8826596,19.4397129 9.60302538,17.5416717 L11.3177506,8.32218031 Z"
              id="Shape"
            ></path>
            <path
              d="M26.8828305,20.8358681 C26.7107939,21.6601776 27.0915306,22.2898163 27.6386634,22.2898163 C28.1857961,22.2898163 29.6495171,21.109624 30.8650543,19.6252585 L31.6208871,20.4739019 C29.4379968,23.1475849 26.5895223,25 24.5363644,25 C22.9823947,25 21.8937698,23.6859715 22.3562943,21.1522083 L24.8296727,8.09100864 L22.7765148,8.09100864 L22.9457311,7.09636209 C25.7095975,6.10475727 28.0560636,3.71091374 30.5294419,0 L31.6208871,0 C30.8227502,1.81287261 30.2756175,3.57099404 29.6918212,6.01046356 L33,6.01046356 L32.6220836,8.09405037 L29.3110845,8.09405037 L26.8828305,20.8358681 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(59.000000, 118.000000)">
            <path
              d="M26.7488007,16.8946689 C26.4317418,19.0729216 25.4775163,21.8970174 24.3251289,24.4980038 L24.2062318,24.7680836 C23.8525891,24.6506576 23.4501681,24.5772663 23.0568931,24.5391029 C22.7002017,24.462776 22.2642457,24.462776 21.7856086,24.462776 C20.7155346,24.462776 19.5631473,24.5772663 18.3314952,24.7328558 C17.1425241,24.8473462 15.8742883,25 14.4841067,25 C9.16727191,25 5.51499664,23.2797088 3.25290294,20.9106388 C0.990809236,18.5386332 0,15.5618835 0,12.9168624 C0,9.10051667 1.58834342,5.92707844 4.36870657,3.59617191 C7.10334006,1.30049319 11.0330419,0 15.6364941,0 C19.8832549,0 22.227662,1.15077501 23.2154226,1.15077501 C23.5751625,1.15077501 23.7702757,0.998121184 23.9318538,0.613550963 L25.4013002,0.613550963 L25.7579915,8.60145608 L23.8922215,8.60145608 C23.4532168,6.65511977 22.4624075,5.04931893 21.0752746,3.93670737 C19.685093,2.82996712 17.9412688,2.22228746 15.9566017,2.22228746 C10.6763506,2.22228746 7.06370769,6.38503992 7.06370769,12.3091827 C7.06370769,18.5386332 10.8348801,22.7777125 16.3925577,22.7777125 C20.1240977,22.7777125 22.980677,20.8284406 24.8830307,16.8946689 L26.7488007,16.8946689 L26.7488007,16.8946689 Z"
              id="Path"
            ></path>
            <path
              d="M57.8815512,10.9705261 L57.8815512,6.20009394 C57.8815512,3.17930953 57.2474333,2.48649601 54.5493835,2.41310474 L54.5493835,0.616486613 L68,0.616486613 L68,2.41310474 C65.1098857,2.48649601 64.5519839,3.17930953 64.5519839,5.6980977 L64.5519839,18.808713 C64.5519839,21.4772194 64.8690428,22.5457961 68,22.5898309 L68,24.386449 L54.5493835,24.386449 L54.5493835,22.5898309 C57.1285362,22.4782762 57.8815512,21.8676609 57.8815512,18.8850399 L57.8815512,13.3395961 L45.9735485,13.3395961 L45.9735485,18.993659 C45.9735485,21.3275012 46.2540238,22.513504 49.427662,22.5898309 L49.427662,24.386449 L35.818516,24.386449 L35.818516,22.5898309 C38.5531495,22.4782762 39.3122618,22.0173791 39.3122618,18.8850399 L39.3122618,6.07679662 C39.3122618,3.02372006 38.4373011,2.41310474 35.818516,2.41310474 L35.818516,0.616486613 L49.427662,0.616486613 L49.427662,2.41310474 C46.7692446,2.56869422 45.9735485,2.90922969 45.9735485,6.03863316 L45.9735485,10.9705261 L57.8815512,10.9705261 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(133.000000, 118.000000)">
            <path
              d="M29.1059354,25 L14.9060856,25 L14.9060856,23.1581798 C17.5687453,23.1581798 18.5063862,22.8451908 18.5063862,21.8279764 C18.5063862,21.3193692 18.2299023,20.3743831 17.6829452,18.9268087 L16.7843727,16.615505 L8.17731029,16.615505 L7.47107438,18.6920669 C6.92712246,20.2901168 6.61157025,21.3885879 6.61157025,21.7045865 C6.61157025,22.7669435 7.90683696,23.1551703 10.0555973,23.1551703 L10.0555973,24.9969905 L0,24.9969905 L0,23.1551703 C2.89406461,23.1551703 3.24567994,22.4900686 4.65815177,18.415192 L10.7648385,0.866738895 L10.4072126,0 L17.4485349,0 L24.5709992,18.5325629 C25.9774606,22.1800891 26.8399699,23.040809 29.1059354,23.1551703 L29.1059354,25 L29.1059354,25 Z M12.2854996,4.89948236 L9.03981968,14.1868304 L15.8888054,14.1868304 L12.2854996,4.89948236 Z"
              id="Shape"
            ></path>
            <path
              d="M39.4259955,7.32815698 C39.4259955,3.13590947 39.2607062,2.66642591 35.9429001,2.47381726 L35.9429001,0.631997111 C38.0976709,0.517635729 40.9887303,0.319008065 43.6453794,0.204646684 C46.2629602,0.0842662814 48.4537941,0.00300951005 49.0488355,0.00300951005 C55.6543952,0.00300951005 60,2.66943542 60,7.60804141 C60,10.9365595 57.7310293,15.282292 50.2659654,15.282292 C49.0157776,15.282292 47.1284748,15.0896834 45.9984974,14.8519321 L45.9984974,20.0583845 C45.9984974,22.3696882 46.6987228,23.1551703 50.4192337,23.1551703 L50.4192337,24.9969905 L35.9459053,24.9969905 L35.9459053,23.1551703 C38.7257701,23.1551703 39.4290008,22.3666787 39.4290008,19.3571687 L39.4290008,7.32815698 L39.4259955,7.32815698 Z M45.9954921,12.9318647 C46.6175808,13.0913687 47.2036063,13.1274828 47.8317055,13.1274828 C51.0803907,13.1274828 53.0338092,10.9726736 53.0338092,7.40640424 C53.0338092,3.96051523 51.1915853,1.88395329 48.1833208,1.88395329 C47.3989482,1.88395329 46.6175808,1.96220055 45.9924869,2.16082822 L45.9924869,12.9318647 L45.9954921,12.9318647 Z"
              id="Shape"
            ></path>
          </g>
          <g id="Group" transform="translate(201.000000, 120.000000)">
            <path
              d="M23.3131603,0 L23.6731973,5.50716226 L21.7899272,5.50716226 C21.4237358,2.99357866 20.8329059,2.29204742 18.8696277,2.29204742 L11.0165145,2.29204742 L11.0165145,10.349716 L16.1770438,10.349716 C19.0357985,10.349716 19.7835675,9.79587552 19.7835675,7.54359101 L21.6729921,7.54359101 L21.6729921,15.2661151 L19.7835675,15.2661151 C19.7835675,13.1984441 19.1496564,12.6474438 16.2293569,12.6474438 L11.0195918,12.6474438 L11.0195918,18.5976784 C11.0195918,20.5602618 11.2565391,20.7051124 13.4198379,20.7051124 L18.0664684,20.7051124 C21.5622115,20.7051124 22.4730742,19.2992097 23.0762129,16.8566313 L24.9564058,16.8566313 L24.4363524,23 L0,23 L0,21.261793 C3.64345061,21.261793 4.28967074,20.4835762 4.28967074,17.8620647 L4.28967074,5.1407755 C4.28967074,1.99666584 3.12647451,1.74104717 0.28310596,1.74104717 L0.28310596,0.00284020746 L23.3131603,0.00284020746 L23.3131603,0 Z"
              id="Path"
            ></path>
            <path
              d="M49.5496974,0 L49.5496974,1.73820696 C46.1431942,1.73820696 45.4631244,2.32897012 45.4631244,5.24586318 L45.4631244,18.0750803 C45.4631244,20.3387256 45.7400759,20.7051124 48.4326598,20.7051124 L51.4729716,20.7051124 C53.9132219,20.7051124 54.753308,20.6341072 58.1228844,16.305631 L60,16.305631 L56.9566109,22.9971598 L34.8158786,22.9971598 L34.8158786,21.2589528 C38.2931583,21.2589528 38.7393579,20.3018029 38.7393579,17.674611 L38.7393579,5.24586318 C38.7393579,2.58458879 38.290081,1.73820696 34.8158786,1.73820696 L34.8158786,0 L49.5496974,0 L49.5496974,0 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(284.000000, 120.000000)">
            <path
              d="M21.8411467,10.0186489 L21.8411467,5.40274176 C21.8411467,2.47980734 21.2104753,1.80943559 18.5429464,1.73842164 L18.5429464,0 L31.85645,0 L31.85645,1.73842164 C28.9837267,1.80943559 28.4375471,2.47980734 28.4375471,4.9170063 L28.4375471,17.6029394 C28.4375471,20.1850068 28.7513741,21.21897 31.85645,21.2615784 L31.85645,23 L18.5429464,23 L18.5429464,21.2615784 C21.0958077,21.1536371 21.8411467,20.562801 21.8411467,17.6767939 L21.8411467,12.3109794 L10.0605669,12.3109794 L10.0605669,17.7818945 C10.0605669,20.0401383 10.3261127,21.1877238 13.4734346,21.2615784 L13.4734346,23 L0,23 L0,21.2615784 C2.71580989,21.1536371 3.45813126,20.7076695 3.45813126,17.6767939 L3.45813126,5.28343831 C3.45813126,2.32925775 2.59208966,1.73842164 0,1.73842164 L0,0 L13.4734346,0 L13.4734346,1.73842164 C10.8451342,1.88897122 10.0605669,2.21847598 10.0605669,5.24651105 L10.0605669,10.0186489 L21.8411467,10.0186489 Z"
              id="Path"
            ></path>
            <path
              d="M42.4420735,23 L42.4420735,21.2615784 C45.230305,21.2615784 45.8187305,20.3043102 45.8187305,17.9296036 L45.8187305,5.28343831 C45.8187305,2.69853032 45.2695334,1.73842164 42.4420735,1.73842164 L42.4420735,0 L56,0 L56,1.73842164 C52.8496605,1.73842164 52.4181485,2.69853032 52.4181485,5.28343831 L52.4181485,17.7108806 C52.4181485,20.0060516 52.6595538,21.2615784 56,21.2615784 L56,23 L42.4420735,23 Z"
              id="Path"
            ></path>
          </g>
          <g id="Group" transform="translate(348.000000, 120.000000)">
            <path
              d="M14.7156266,0 L14.7156266,1.73842164 C11.3201855,1.73842164 10.6410973,2.32925775 10.6410973,5.24651105 L10.6410973,18.0773126 C10.6410973,20.3412375 10.9207218,20.7076695 13.5971283,20.7076695 L16.6453433,20.7076695 C19.0789988,20.7076695 19.9209453,20.6366556 23.2795128,16.3076448 L25.156992,16.3076448 L22.1149226,23 L0,23 L0,21.2615784 C3.48147941,21.2615784 3.91781664,20.3043102 3.91781664,17.6767939 L3.91781664,5.24651105 C3.91781664,2.58490799 3.47840661,1.73842164 0,1.73842164 L0,0 L14.7156266,0 L14.7156266,0 Z"
              id="Path"
            ></path>
            <path
              d="M44.567853,0 L44.567853,1.73842164 C41.1601207,1.73842164 40.4810325,2.32925775 40.4810325,5.24651105 L40.4810325,18.0773126 C40.4810325,20.3412375 40.7668026,20.7076695 43.4432091,20.7076695 L46.4791329,20.7076695 C48.9220068,20.7076695 49.7547349,20.6366556 53.1225208,16.3076448 L55,16.3076448 L51.967149,23 L29.843008,23 L29.843008,21.2615784 C33.3214146,21.2615784 33.7577518,20.3043102 33.7577518,17.6767939 L33.7577518,5.24651105 C33.7577518,2.58490799 33.3183418,1.73842164 29.843008,1.73842164 L29.843008,0 L44.567853,0 L44.567853,0 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
