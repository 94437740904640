import React from "react";
import { getIcon } from "./utils/get-icon/getIcon";

export const SvgWrapper = ({
  iconType,
  height,
  width,
  color,
  opacity,
  maxHeight,
  maxWidth,
  onMouseEnter,
  onMouseLeave,
}) => {
  const Icon = getIcon(iconType);
  console.log("Icon", Icon);
  return (
    <Icon
      height={height}
      width={width}
      color={color}
      opacity={opacity}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    />
  );
};
