import { Context } from "contexts/Context.store";
import React, { useEffect, useMemo, useState } from "react";
import "styles/style.scss";

const PacBayApp = ({ element }) => {
  const [isSplashPageLoading, toggleIsSplashPageLoading] = useState(false);
  const [isMenuOpen, toggleIsMenuOpen] = useState(false);
  const [scrollY, setScrollY] = useState(0);
  const [relatedPages, setRelatedPages] = useState([]);
  const [isHidingMenu, toggleIsHidingMenu] = useState(true);
  const isScrollYPassedTriggerPoint = (triggerPoint) => scrollY > triggerPoint;

  const value = useMemo(
    () => ({
      isSplashPageLoading,
      toggleIsSplashPageLoading,
      isMenuOpen,
      toggleIsMenuOpen,
      relatedPages,
      setRelatedPages,
      isScrollYPassedTriggerPoint,
      isHidingMenu,
      toggleIsHidingMenu,
    }),
    [
      isSplashPageLoading,
      isMenuOpen,
      relatedPages,
      isScrollYPassedTriggerPoint,
      isHidingMenu,
    ]
  );

  const _setScrollY = () => {
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", _setScrollY);

    return () => {
      window.removeEventListener("scroll", _setScrollY);
    };
  }, []);

  useEffect(() => {
    if (!isHidingMenu) {
      if (isSplashPageLoading || isMenuOpen) {
        document.body.style.overflowY = "hidden";
      } else {
        document.body.style.overflowY = "auto";
      }
    }
  }, [isHidingMenu, isSplashPageLoading, isMenuOpen]);

  return <Context.Provider value={value}>{element}</Context.Provider>;
};

const Wrapper = ({ element }) => {
  return <PacBayApp element={element} />;
};

export const wrapRootElement = Wrapper;
