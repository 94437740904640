import { motion } from "framer-motion";
import React from "react";
import { lineOne, lineThree, lineTwo } from "./Menu.config";
import "./Menu.styles.scss";

export const Menu = ({ isMenuOpen, showWhite }) => {
  const animatedLineProps = (line) => {
    const { open, closed } = line;
    return {
      animate: isMenuOpen ? open : closed,
      transition: { type: "tween" },
    };
  };

  return (
    <svg
      className="drop-shadow-1 responsive-menu-svg"
      viewBox="0 0 193 155"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>{isMenuOpen ? "Close Menu" : "Open Menu"}</title>
      <g
        id="Toggle-Menu-Button"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <motion.g
          id="Group"
          transform="translate(97.000000, 77.500000) scale(-1, 1) translate(-97.000000, -77.500000) translate(23.000000, 23.000000)"
          animate={{
            stroke: showWhite ? "var(--white)" : "var(--primary)",
          }}
          strokeWidth="25"
        >
          <motion.line {...animatedLineProps(lineOne)} id="Path" />
          <motion.line {...animatedLineProps(lineTwo)} id="Path" />
          <motion.line {...animatedLineProps(lineThree)} id="Path" />
        </motion.g>
      </g>
    </svg>
  );
};
