import { Context } from "contexts/Context.store";
import { motion } from "framer-motion";
import React, { useContext } from "react";
import "./MenuSidePanel.styles.scss";

export const MenuSidePanel = ({ children }) => {
  const { isMenuOpen } = useContext(Context);
  return (
    <motion.main
      role="dialog"
      aria-hidden={!isMenuOpen}
      className="menu-panel layer-2"
      initial={{ width: "0vw" }}
      animate={{ width: isMenuOpen ? "100vw" : "0vw" }}
      transition={{ type: "tween" }}
    >
      {children}
    </motion.main>
  );
};
