const LinksService = () => {
  const documentLink = (route) => `/documents${route}`;
  //* local pages
  const admissions = () => "/admissions";
  const affordingPacbay = () => "/affording-pacbay";
  const careers = () => "/careers";
  const collegeCounseling = () => "/college-counseling";
  const donations = () => "/donations";
  const highSchool = () => "/high-school";
  const home = () => "/";
  const howToApply = () => "/how-to-apply";
  const inquiryForm = () => "/inquiry-form";
  const middleSchool = () => "/middle-school";
  const ourStory = () => "/our-story";
  const upcomingEvents = () => "/upcoming-events";
  const upcomingEventsHighSchool = () => "/upcoming-events/high-school";
  //* embed document pages
  const connectionsForLife = () => documentLink("/connections-for-life");
  const covid19 = () => documentLink("/covid-19");
  const dressCode = () => documentLink("/dress-code");
  const highSchoolCurriculum = () => documentLink("/high-school-curriculum");
  const middleSchoolCurriculum = () =>
    documentLink("/middle-school-curriculum");
  const monthlyBudgetWorksheet = () =>
    documentLink("/monthly-budget-worksheet");
  const pacbayDemographics = () => documentLink("/pacbay-demographics");
  const schoolCalendar = () => documentLink("/school-calendar");
  const schoolProfile = () => documentLink("/school-profile");
  const statementOnEcumenicalism = () =>
    documentLink("/statement-on-ecumenicalism");
  //* external pages
  const applyToFAST = () =>
    "https://www.ismfast.com/FastPage.php?id=be5e133864d0d8e2ae0e0e3a9d5c16c4";
  const caceArticle = () =>
    "https://cace.org/diversity-equity-and-inclusion-in-america/";
  const cedaPanel = () => "https://www.youtube.com/watch?v=YNNpki7FCZA";
  const collegeLogin = () =>
    "https://app.scoir.com/admissions/recruit/visits/view/month?hsid=5b6c2ed3884fe90001644310";
  const donate100 = () => "https://donate.stripe.com/4gwdT653q3T039S28a";
  const donate1000 = () => "https://donate.stripe.com/4gw5mA1ReexE9ygeUX";
  const donate250 = () => "https://donate.stripe.com/dR62ao9jG6186m47st";
  const donate500 = () => "https://donate.stripe.com/3cs6qE7by1KS5i03cg";
  const donateOther = () => "https://donate.stripe.com/bIY02g7by4X4h0IfYY";
  const email = () => "mailto:admissions@pacbay.org";
  const facebook = () => "https://www.facebook.com/pacificbaychristian/";
  const googleSchoolDirections = () =>
    "https://www.google.com/maps/place/Pacific+Bay+Christian+School/@37.586991,-122.4949781,17z/data=!3m1!4b1!4m5!3m4!1s0x808f7062b405e889:0x684de80dccd111af!8m2!3d37.5869868!4d-122.4927894";
  const instagram = () => "https://www.instagram.com/pacificbaychristian/";
  const linkedin = () =>
    "https://www.linkedin.com/company/pacific-bay-christian-school";
  const myPacbay = () => "https://mypacbay.myschoolapp.com/";
  const outdoorEducationVideo = () =>
    "https://www.youtube.com/watch?v=etZaB1_T0Bo";
  const phone = () => "tel:+16503551935";
  const scheduleHighSchoolCampusVisit = () =>
    "https://calendly.com/pacbay-admissions/high-school-campus-visit-day";
  const scheduleHighSchoolOpenHouse = () =>
    "https://calendly.com/pacbay-admissions/pacbay-high-school-open-house";
  const scheduleMiddleSchoolCampusVisit = () =>
    "https://calendly.com/pacbay-admissions/middle-school-campus-visit-day-clone";
  const scheduleMiddleSchoolOpenHouse = () =>
    "https://calendly.com/pacbay-admissions/pacbay-middle-school-open-house?month=2023-02";
  const scheduleVisit = () =>
    "https://calendly.com/pacbay-admissions/campus-tour";
  const theUndefeatedArticle = () =>
    "https://theundefeated.com/features/some-christian-schools-are-finally-grappling-with-their-racist-past-and-segregated-present/";
  const trinityInstituteEndowment = () =>
    "https://www.bostontrinity.org/purposeful-faith/trinityinstitute/endowment";
  const twitter = () => "https://twitter.com/pacbay1";
  const venmo = () =>
    "https://venmo.com/?txn=pay&audience=private&recipients=pacbay&note=Donation%20Tuition%20Assistance%20Fund";
  const worldVisionIgnite = () => "https://www.worldvision.org/ignite/";

  return {
    //* local pages
    admissions,
    affordingPacbay,
    careers,
    collegeCounseling,
    donations,
    highSchool,
    home,
    howToApply,
    inquiryForm,
    middleSchool,
    ourStory,
    upcomingEvents,
    upcomingEventsHighSchool,
    //* embed document pages
    connectionsForLife,
    covid19,
    dressCode,
    highSchoolCurriculum,
    middleSchoolCurriculum,
    monthlyBudgetWorksheet,
    pacbayDemographics,
    schoolCalendar,
    schoolProfile,
    statementOnEcumenicalism,
    //* external pages
    applyToFAST,
    caceArticle,
    cedaPanel,
    collegeLogin,
    donate100,
    donate1000,
    donate250,
    donate500,
    donateOther,
    email,
    facebook,
    googleSchoolDirections,
    instagram,
    linkedin,
    myPacbay,
    outdoorEducationVideo,
    phone,
    scheduleHighSchoolCampusVisit,
    scheduleHighSchoolOpenHouse,
    scheduleMiddleSchoolCampusVisit,
    scheduleMiddleSchoolOpenHouse,
    scheduleVisit,
    theUndefeatedArticle,
    trinityInstituteEndowment,
    twitter,
    venmo,
    worldVisionIgnite,
  };
};

export default LinksService();
