import React from "react";

export const Tour = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 48 48"
      fill={color}
      fillOpacity={opacity}
    >
      <path d="M10 44V4h3v4.2h29l-4.05 9.65L42 27.5H13V44Zm3-32.8v13.3Zm12.05 10.25q1.5 0 2.55-1.05 1.05-1.05 1.05-2.55 0-1.5-1.05-2.55-1.05-1.05-2.55-1.05-1.5 0-2.55 1.05-1.05 1.05-1.05 2.55 0 1.5 1.05 2.55 1.05 1.05 2.55 1.05ZM13 24.5h24.55l-2.75-6.65 2.75-6.65H13Z" />
    </svg>
  );
};
