import { throttle } from "lodash";
import { useEffect, useState } from "react";

export const useWindowWidth = () => {
  const [windowWidth, setWindowWidth] = useState(0);

  useEffect(() => {
    const windowWidthListener = () => {
      setWindowWidth(document.body.clientWidth);
    };
    window.addEventListener("resize", throttle(windowWidthListener, 100));
    const timeout = setTimeout(windowWidthListener, 500);
    return () => {
      window.removeEventListener("resize", windowWidthListener);
      clearTimeout(timeout);
    };
  }, []);

  return windowWidth;
};
