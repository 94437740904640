import classNames from "classnames";
import { Button } from "components/button/Button.component";
import { Link } from "components/link/Link.component";
import { Menu } from "components/svg-wrapper/components/menu/Menu.svg";
import { Context } from "contexts/Context.store";
import { AnimatePresence, motion } from "framer-motion";
import { PacBayLogoBasic } from "images/svg/logos/PacBayLogo_Basic.svg";
import React, { useContext, useEffect } from "react";
import linksService from "services/links.service";
import "./Header.styles.scss";

export const Header = ({ location, hideMenu }) => {
  const {
    isMenuOpen,
    toggleIsMenuOpen,
    isScrollYPassedTriggerPoint,
    isSplashPageLoading,
    toggleIsHidingMenu,
  } = useContext(Context);
  const isHomePage = location.pathname === "/";
  const showWhiteHeaderSVGs =
    isMenuOpen || (isHomePage && !isScrollYPassedTriggerPoint(1));

  useEffect(() => {
    toggleIsHidingMenu(hideMenu);
  }, [hideMenu]);

  return (
    <header
      className={classNames(["layer-3"], {
        "altered-header-height":
          !isHomePage || isScrollYPassedTriggerPoint(450),
        "altered-header-color":
          (!isHomePage && !isMenuOpen) ||
          (!isMenuOpen && isScrollYPassedTriggerPoint(550)),
      })}
    >
      <Link to={linksService.home()}>
        <PacBayLogoBasic
          className="responsive-logo-svg"
          primary={showWhiteHeaderSVGs ? "var(--white)" : "var(--primary)"}
          secondary="var(--secondary)"
          tertiary="var(--teal)"
        />
      </Link>

      {!hideMenu && (
        <AnimatePresence>
          {!isSplashPageLoading && (
            <motion.div
              initial={{ visibility: "hidden", opacity: 0, scale: 0.75 }}
              animate={{ visibility: "visible", opacity: 1, scale: 1 }}
              exit={{ visibility: "hidden", opacity: 0, scale: 0.75 }}
            >
              <Button
                className="menu-button no-shadow-or-bg-color"
                labelStyle={{ margin: 0 }}
                onClick={() => {
                  toggleIsMenuOpen(!isMenuOpen);
                }}
              >
                <Menu isMenuOpen={isMenuOpen} showWhite={showWhiteHeaderSVGs} />
              </Button>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </header>
  );
};
