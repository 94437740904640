import React from "react";
import "styles/animations/infiniteScroll.animation.scss";
import { DefaultLayout } from "./DefaultLayout.component";
import { MarketingLayout } from "./MarketingLayout.component";
import { NoLayout } from "./NoLayout.component";

const Index = ({ children, pageContext, location }) => {
  if (pageContext.layout === "no-layout") {
    return <NoLayout>{children}</NoLayout>;
  } else if (pageContext.layout === "minimal") {
    return <MarketingLayout>{children}</MarketingLayout>;
  }
  return <DefaultLayout location={location}>{children}</DefaultLayout>;
};

export default Index;
