import React from "react";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";

export const Link = ({ to, children, newTab, className, style, onClick }) => {
  const {
    allContentfulDocumentRoute: { edges: specialPages },
  } = useStaticQuery(graphql`
    query GetDocumentPagesQuery {
      allContentfulDocumentRoute {
        edges {
          node {
            slug
          }
        }
      }
    }
  `);

  const isExternal =
    to.charAt(0) !== "/" ||
    specialPages.some(({ node: specialPage }) => to.match(specialPage.slug));
  return (
    <>
      {isExternal ? (
        <a
          href={to}
          target={newTab ? "_blank" : "_self"}
          rel="noopener noreferrer"
          className={className}
          style={style}
          onClick={onClick}
        >
          {children}
        </a>
      ) : (
        <GatsbyLink to={to} className={className} onClick={onClick}>
          {children}
        </GatsbyLink>
      )}
    </>
  );
};
