import React from "react";

export const LargeSchool = ({
  width,
  height,
  color,
  opacity = "1",
  maxWidth,
  maxHeight,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={{ maxWidth, maxHeight }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      fillOpacity={opacity}
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M5 13.18v2.81c0 .73.4 1.41 1.04 1.76l5 2.73c.6.33 1.32.33 1.92 0l5-2.73c.64-.35 1.04-1.03 1.04-1.76v-2.81l-6.04 3.3c-.6.33-1.32.33-1.92 0L5 13.18zm6.04-9.66l-8.43 4.6c-.69.38-.69 1.38 0 1.76l8.43 4.6c.6.33 1.32.33 1.92 0L21 10.09V16c0 .55.45 1 1 1s1-.45 1-1V9.59c0-.37-.2-.7-.52-.88l-9.52-5.19c-.6-.32-1.32-.32-1.92 0z" />
    </svg>
  );
};
