export const lineOne = {
  closed: {
    x1: 0,
    y1: 108.5,
    x2: 102.570833,
    y2: 108.5,
  },
  open: {
    x1: -1.25940924e-14,
    y1: -1.49880108e-14,
    x2: 119,
    y2: 108,
  },
};

export const lineTwo = {
  closed: {
    x1: 1,
    y1: 54.5,
    x2: 147.572674,
    y2: 54.5,
  },
  open: {
    x1: 54.5,
    y1: 54.5,
    x2: 54.5,
    y2: 54.5,
  },
};

export const lineThree = {
  closed: {
    x1: 0,
    y1: 0.5,
    x2: 126.573826,
    y2: 0.5,
  },
  open: {
    x1: -1.25940924e-14,
    y1: 108,
    x2: 119,
    y2: 0,
  },
};
