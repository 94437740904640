import { Link } from "components/link/Link.component";
import { Context } from "contexts/Context.store";
import { graphql, useStaticQuery } from "gatsby";
import { PacBayLogoFull } from "images/svg/logos/PacBayLogo_Full.svg";
import React, { useContext } from "react";
import { RelatedPages } from "./components/related-pages/RelatedPages.component";
import { footerCategories } from "./Footer.config";
import "./Footer.styles.scss";

const renderColumnCategory = ({ columnName, columnItems, isContactInfo }) => {
  const renderItems = () => {
    return columnItems.map((item) => (
      <dd key={item.label} className="column-text">
        <Link
          to={item.link}
          newTab={item.newTab}
          className="invert-color"
          to={item.link}
        >
          {item.label}
        </Link>
      </dd>
    ));
  };
  return (
    <>
      <dt className="column-name">{columnName}</dt>
      {isContactInfo ? <address>{renderItems()}</address> : renderItems()}
    </>
  );
};

export const Footer = () => {
  const { relatedPages, isSplashPageLoading } = useContext(Context);

  const {
    allContentfulFooterDisclaimer: {
      edges: {
        0: {
          node: { disclaimer },
        },
      },
    },
  } = useStaticQuery(graphql`
    query FooterDisclaimer {
      allContentfulFooterDisclaimer(limit: 1) {
        edges {
          node {
            disclaimer {
              id
              internal {
                content
              }
            }
          }
        }
      }
    }
  `);

  return (
    <footer>
      <section className="top-row">
        {relatedPages.length > 0 && !isSplashPageLoading && (
          <RelatedPages relatedPages={relatedPages} />
        )}
        <PacBayLogoFull
          width="7rem"
          primary="var(--white)"
          secondary="var(--secondary)"
          tertiary="var(--teal)"
          text="var(--white)"
        />
        <address className="column-text">
          1030 Linda Mar Boulevard
          <br />
          Pacifica, CA 94044
        </address>
      </section>
      <section className="middle-row">
        {footerCategories.map((columnCategory) => (
          <article key={columnCategory.columnName}>
            {renderColumnCategory(columnCategory)}
          </article>
        ))}
      </section>
      <section className="bottom-row">
        <p className="nondiscriminatory-clause">
          {disclaimer?.internal.content}
        </p>
        © 2018-{new Date().getFullYear()}, Pacific Bay Christian School. All
        Rights Reserved
      </section>
    </footer>
  );
};
